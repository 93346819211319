import Button from "../Button"
import styles from "./MacroCardPreview.module.css"
import { ReactComponent as ArrowIcon } from "../../assets/images/icons/ic-arrow.svg"
import { useNavigate } from "react-router-dom"
import { useEffect, useState } from "react"

const MacroCardPreview = ({ macro }) => {

    return (
        <div className={styles.container}>
            <div className={styles.header} style={{ backgroundImage: macro.background }}>
                <div className={styles.title}>
                    {macro.name}
                </div>
                <div className={styles.subtitle}>
                    {macro.subtitle}
                </div>
            </div>
            <div className={styles.description}>
                {macro.description}
            </div>
            <Button
                fullWidth
                accentColor={"var(--tertiary)"}
                additionalClass={styles.actionButton}
            >
                ESPLORA
                {/* ESPLORA {macro.name.toUpperCase()} */}
                <ArrowIcon style={{ transform: 'rotate(180deg)', width: '16px', height: '16px', minWidth: '16px', minHeight: '16px' }} />
            </Button>
        </div>
    )
}

export default MacroCardPreview
