import { useEffect, useState } from "react"
import { ChromePicker } from "react-color"
import { ReactComponent as AddIcon } from "../assets/images/icons/ic-add-circle.svg"
import { ReactComponent as ChevronIcon } from "../assets/images/icons/ic-chevron.svg"
import { Each } from "../common/Each"
import Button from "./Button"
import styles from "./CourseInfoEditor.module.css"
import TextInput from "./TextInput"
import { ReactSortable } from "react-sortablejs"

const CourseInfoEditor = ({ info, onChange = () => { }, shouldChangeIcon = () => { } }) => {

    const [courseInfo, setCourseInfo] = useState(info)
    const [pickerOpen, setPickerOpen] = useState(info.map(i => false))

    useEffect(() => {
        onChange(courseInfo)
    }, [courseInfo])

    return (
        <div className={styles.container}>
            <ReactSortable list={courseInfo} setList={setCourseInfo} draggable=".sortable-info" handle=".handle" dragClass={styles.dragClass}>
                <Each of={courseInfo} render={(item, index) => {
                    return (
                        <div className={`${styles.item} ${item._selected ? styles.open : ''} sortable-info`}>
                            <div className={`${styles.header} ${item._selected ? styles.open : ''} handle`} onClick={() => {
                                let newCourseInfo = courseInfo.map((itm, idx) => {
                                    if (idx === index) {
                                        itm._selected = !itm._selected
                                    }
                                    return itm
                                })
                                setCourseInfo([...newCourseInfo])
                            }}>
                                <div className={styles.title}>{item.title}</div>
                                <ChevronIcon className={`${styles.chevron} ${item._selected ? styles.open : ''}`} />
                            </div>
                            <div className={`${styles.content} ${item._selected ? styles.open : ''}`} >
                                <div className={styles.changeIcon} onClick={() => { shouldChangeIcon(item, index) }}>
                                    <>
                                        {item.icon &&
                                            // <img src={item.icon} alt="" className={styles.icon} />
                                            <div className={styles.icon}
                                                dangerouslySetInnerHTML={{ __html: item.icon.replace(/fill="#[^"]*"/g, `fill="${item.color}"`).replace(/stroke="#[^"]*"/g, 'stroke="currentColor"') }}
                                            />
                                        }
                                        <div className={styles.colorChange} style={{ backgroundColor: item.color }} onClick={(e) => {
                                            e.stopPropagation()
                                            let po = pickerOpen.map((i, idx) => {
                                                if (idx === index) {
                                                    return !i
                                                }
                                                return i
                                            })
                                            setPickerOpen([...po])
                                        }}>
                                            <ChevronIcon className={`${styles.colorChangeChevron} ${pickerOpen[index] ? styles.open : ''}`} />
                                        </div>
                                    </>
                                </div>
                                {pickerOpen[index] &&
                                    <div className={styles.colorPicker}>
                                        <ChromePicker disableAlpha color={item.color} onChange={(color) => {
                                            let newCourseInfo = courseInfo.map((itm, idx) => {
                                                if (idx === index) {
                                                    itm.color = color.hex
                                                }
                                                return itm
                                            })
                                            setCourseInfo([...newCourseInfo])
                                        }} />
                                    </div>
                                }
                                <TextInput 
                                style={{fontSize: '.75rem'}}
                                type="text" 
                                placeholder={"Titolo"} 
                                value={item.title} 
                                onKeyUp={(value) => {
                                    let newCourseInfo = courseInfo.map((itm, idx) => {
                                        if (idx === index) {
                                            itm.title = value
                                        }
                                        return itm
                                    })
                                    setCourseInfo([...newCourseInfo])
                                }} />
                                <textarea className={styles.description} value={item.description} placeholder="Descrizione" onChange={(e) => {
                                    let value = e.target.value
                                    let newCourseInfo = courseInfo.map((itm, idx) => {
                                        if (idx === index) {
                                            itm.description = value
                                        }
                                        return itm
                                    })
                                    setCourseInfo([...newCourseInfo])
                                }} />
                                 <Button onClick={() => {
                                    let newCourseInfo = courseInfo.filter((itm, idx) => {
                                        return (idx !== index)
                                    })
                                    setCourseInfo([...newCourseInfo])
                                    
                                }}  appearance="text" accentColor="var(--secondary)" style={{width: 'fit-content'}}>Elimina</Button>
                            </div>
                        </div>
                    )
                }} />
            </ReactSortable>
            <Button fullWidth accentColor="var(--tertiary)"
                style={{ borderRadius: '0 0 1rem 1rem' }}
                onClick={() => {
                    let newItem = { icon: '', title: 'Nuovo', description: '' }
                    setCourseInfo((prev) => {
                        return [...prev, newItem]
                    })
                    setPickerOpen([...pickerOpen, false])
                }}>
                <AddIcon className={styles.addIcon} />
                AGGIUNGI
            </Button>
        </div>
    )
}

export default CourseInfoEditor