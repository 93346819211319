import styles from "./WritingLoader.module.css"

const WritingLoader = () => {

    return (
        <div className={styles.loader}>
            <li className={styles.ball}></li>
            <li className={styles.ball}></li>
            <li className={styles.ball}></li>
        </div>
    )

}

export default WritingLoader