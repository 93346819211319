import styles from "./Button.module.css"
import { ReactComponent as CheckIcon } from "../assets/images/icons/ic-check.svg"
import { ReactComponent as ErrorIcon } from "../assets/images/icons/ic-close.svg"
import { ButtonStatus } from "../common/constants"
import Spinner from "./Spinner"

const Button = ({ children, inverse, appearance = 'default', onClick, fullWidth, disabled, style, additionalClass, accentColor, status, ...props }) => {

    const handleOnClick = async (e) => {
        if (onClick && typeof onClick === 'function') {
            onClick(e)
        }
    }

    let classes = []

    switch (appearance) {
        case 'default':
            classes.push(styles.actionButton)
            break
        case 'text':
            classes.push(styles.textButton)
            break
        case 'social':
            classes.push(styles.socialButton)
            break
        default:
            classes.push(styles.actionButton)
    }

    if (fullWidth) {
        classes.push(styles.fullWidth)
    }

    if (additionalClass) {
        classes.push(additionalClass)
    }

    if (inverse) {
        classes.push(styles.inverse)
    }

    return (
        <button className={classes.join(' ')}
            style={{ ...style, '--button-accent-color': accentColor ? accentColor : 'var(--primary)' }}
            onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
                handleOnClick()
            }} disabled={disabled}>
            {children}
            {status === ButtonStatus.Loading && <Spinner />}
            {status === ButtonStatus.Success && <CheckIcon className={styles.successIcon} />}
            {status === ButtonStatus.Error && <ErrorIcon className={styles.errorIcon} />}
        </button>
    )
}

export default Button
