import { useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import { useTranslation } from "react-i18next";
import ReactPlayer from "react-player";
import { useNavigate, useParams } from "react-router-dom";
import api from "../api";
import { ReactComponent as CloseIcon } from "../assets/images/icons/ic-close.svg";
import Back from "../components/Back";
import Button from "../components/Button";
import CheckbokSelect from "../components/CheckboxSelect";
import Select from "../components/Select";
import TagSelector from "../components/TagSelector";
import TextInput from "../components/TextInput";
import HeaderFooterLayout from "../components/layouts/HeaderFooterLayout";
import CareerPreview from "../components/previews/CareerPreview";
import typo from "../typography.module.css";
import styles from "./Career.module.css";
import Spinner from "../components/Spinner"
import AlertDialog from "../components/dialogs/AlertDialog"
import { DialogStatus } from "../enums";
import { CourseType } from "../common/constants";
import { emptyOrNull } from "../utils";

const Career = () => {

    const { t } = useTranslation()
    const navigate = useNavigate()
    const { careerId } = useParams();
    const [courses, setCourses] = useState(null)
    const [career, setCareer] = useState(null)
    const [careerCourse, setCareerCourse] = useState(null)
    const [careerPartners, setCareerPartners] = useState(null)
    const [careerCourses, setCareerCourses] = useState(null)
    const [careerArticles, setCareerArticles] = useState(null)
    const [video, setVideo] = useState(null)
    const [thumbnail, setThumbnail] = useState(null)

    const [partners, setPartners] = useState(null)
    const [tags, setTags] = useState(null)

    const [saveDisabled, setSaveDisabled] = useState(true)

    const [status, setStatus] = useState(
        [
            { id: 0, name: 'Bozza', value: 'draft' },
            { id: 1, name: 'Pubblico', value: 'public' },
            { id: 2, name: 'Nascosto', value: 'hidden' }
        ]
    )

    const [loading, setLoading] = useState(false)

    const [alert, setAlert] = useState({ open: false, title: '', text: '', actions: [], status: DialogStatus.Default })

    useEffect(() => {

        const getCareer = async () => {
            try {
                const career = await api.get(`/admin/careers/${careerId}`)
                setCareer(career)
                initializeStatus(career.status)
            }
            catch (e) {
                console.error(e)
            }
        }

        const getCareerPartners = async () => {
            try {
                const partners = await api.get(`/admin/careers/${careerId}/partners`)
                setCareerPartners(partners)
            }
            catch (e) {
                console.error(e)
            }
        }

        const getCareerCourse = async () => {
            try {
                const course = await api.get(`/admin/careers/${careerId}/course`)
                setCareerCourse(course)
            }
            catch (e) {
                console.error(e)
            }
        }

        const getCareerRelatesCourses = async () => {
            try {
                const relatedCourses = await api.get(`/admin/careers/${careerId}/courses`)
                setCareerCourses(relatedCourses)
            }
            catch (e) {
                console.error(e)
            }
        }

        const getCareerRelatedArticles = async () => {
            try {
                const relatedArticles = await api.get(`/admin/careers/${careerId}/articles`)
                setCareerArticles(relatedArticles)
            }
            catch (e) {
                console.error(e)
            }
        }

        const getCourses = async () => {
            try {
                const courses = await api.get("/admin/courses")
                setCourses(courses.filter(c => c.type === CourseType.Professional))
            }
            catch (e) {
                console.error(e)
            }
        }

        if (!career && careerId !== 'create') {
            getCourses()
            getCareer()
            getCareerPartners()
            getCareerCourse()
            getCareerRelatesCourses()
            getCareerRelatedArticles()
        }

        if (!career && careerId === 'create') {
            setCareerPartners([])
            setCareer({
                name: 'Nuova Carriera',
                description: '',
                status: 'draft',
                slug: 'nuova-carriera',
                thumbnail: '',
                video_url: '',
                salary_avg: '',
                course_id: -1,
                tags: []
            })
            initializeStatus('draft')
        }

    }, [careerId])

    useEffect(() => {
        const getPartners = async () => {
            try {
                const partners = await api.get("/admin/partners")
                setPartners(partners)
            }
            catch (e) {
                console.error(e)
            }
        }

        if (!partners) {
            getPartners()
        }
    }, [partners])

    useEffect(() => {
        const getTags = async () => {
            try {
                let tags = await api.get("/admin/tags?type=general")
                tags = tags.filter(t => {
                    return !career.tags.map(t => t.id).includes(t.id)
                })
                setTags(tags)
            }
            catch (e) {
                console.error(e)
            }
        }
        if (!tags && career) {
            getTags()
        }
    }, [tags, career])

    const initializeStatus = (status) => {
        switch (status) {
            case 'draft':
                setStatus([
                    { id: 0, name: 'Bozza', value: 'draft' },
                    { id: 1, name: 'Pubblico', value: 'public' },
                ])
                break
            case 'public':
                setStatus([
                    { id: 1, name: 'Pubblico', value: 'public' },
                    { id: 2, name: 'Nascosto', value: 'hidden' }
                ])
                break
            case 'hidden':
                setStatus([
                    { id: 1, name: 'Pubblico', value: 'public' },
                    { id: 2, name: 'Nascosto', value: 'hidden' }
                ])
                break
            default: return
        }
    }

    useEffect(() => {
        if (career) {
            switch (career.status) {
                case 'draft':
                    setSaveDisabled(career.name.trim() === '' || career.slug.trim() === '')
                    break
                case 'public':
                case 'hidden':
                    setSaveDisabled(
                        career.name.trim() === '' ||
                        career.description.trim() === '' ||
                        career.slug.trim() === '' ||
                        career.video_url.trim() === '' ||
                        career.thumbnail.trim() === '' ||
                        career.salary_avg === '')
                    break
                default: return
            }
        }
    }, [career])

    const update = async () => {
        setLoading(true)
        try {
            let form = new FormData()
            form.append('name', career.name)
            form.append('description', career.description)
            form.append('slug', career.slug)
            form.append('status', career.status)
            if (video) {
                form.append('videoFile', video)
            }
            if (thumbnail) {
                form.append('thumbnailFile', thumbnail)
            }

            form.append('course_id', career.course_id ?? '')
            form.append('video_url', career.video_url)
            form.append('thumbnail', career.thumbnail)
            form.append('salary_avg', career.salary_avg)
            form.append('tags', JSON.stringify(career.tags.map(t => t.id)))
            form.append('partners', JSON.stringify(careerPartners.map(p => p.id)))

            const updated = await api.put(`/admin/careers/${career.id}`, form, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })

            setCareer(updated)
            initializeStatus(updated.status)

            setAlert({
                open: true,
                title: 'Operazione Completata',
                text: 'I dati sono stati aggiornati con successo',
                status: DialogStatus.Success,
                actions: [
                    {
                        label: 'CHIUDI',
                        onClick: () => {
                            setAlert((prev) => {
                                prev.open = false
                                return { ...prev }
                            })
                        }
                    }
                ]
            })
        }
        catch (e) {
            console.error(e)
            setAlert({
                open: true,
                title: 'Operazione Fallita',
                text: e.detail,
                status: DialogStatus.Error,
                actions: [
                    {
                        label: 'OK',
                        onClick: () => {
                            setAlert((prev) => {
                                prev.open = false
                                return { ...prev }
                            })
                        }
                    }
                ]
            })
        }
        setLoading(false)

    }

    const create = async () => {
        setLoading(true)
        try {
            let form = new FormData()
            form.append('name', career.name)

            if (!emptyOrNull(career.description)) {
                form.append('description', career.description)
            }
            if (!emptyOrNull(career.slug)) {
                form.append('slug', career.slug)
            }

            form.append('status', career.status)
            if (video) {
                form.append('videoFile', video)
            }
            if (thumbnail) {
                form.append('thumbnailFile', thumbnail)
            }

            form.append('course_id', career.course_id === -1 ? '' : career.course_id)

            if (!emptyOrNull(career.video_url)) {
                form.append('video_url', career.video_url)
            }
            if (!emptyOrNull(career.thumbnail)) {
                form.append('thumbnail', career.thumbnail)
            }
            if (!emptyOrNull(career.salary_avg)) {
                form.append('salary_avg', career.salary_avg)
            }
            form.append('tags', JSON.stringify(career.tags.map(t => t.id)))
            form.append('partners', JSON.stringify(careerPartners.map(p => p.id)))


            const created = await api.post(`/admin/careers`, form, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })

            setCareer(created)
            initializeStatus(created.status)

            setAlert({
                open: true,
                title: 'Operazione Completata',
                text: 'I dati sono stati aggiornati con successo',
                status: DialogStatus.Success,
                actions: [
                    {
                        label: 'CHIUDI',
                        onClick: () => {
                            setAlert((prev) => {
                                prev.open = false
                                return { ...prev }
                            })
                        }
                    }
                ]
            })
        }
        catch (e) {
            console.error(e)
            setAlert({
                open: true,
                text: e.detail,
                status: DialogStatus.Error,
                actions: [
                    {
                        label: 'OK',
                        onClick: () => {
                            setAlert((prev) => {
                                prev.open = false
                                return { ...prev }
                            })
                        }
                    }
                ]
            })
        }
        setLoading(false)

    }

    const save = async () => {
        if (career.id) {
            update()
        }
        else {
            create()
        }
    }

    const getCourse = async (course_id) => {
        try {
            let course = await api.get(`/admin/courses/${course_id}`)
            return course
        }
        catch (e) {
            console.error(e)
        }
    }

    return (
        <HeaderFooterLayout hideFooter>
            <div className={styles.container}>
                <div className={styles.preview}>
                    <div className={styles.back}>
                        <Back onClick={() => { navigate("/careers") }} />
                    </div>
                    <CareerPreview
                        career={career}
                        partners={careerPartners}
                        course={careerCourse}
                        relatedCourses={careerCourses}
                        relatedArticles={careerArticles} />
                </div>
                
                <div className={styles.edit}>
                    <div className={typo.subtitle}>Modifica</div>
                    {career &&
                        <>
                            <div className={typo.caption}>STATO</div>
                            <Select selected={status.find(s => s.value === career?.status)} options={status} onSelect={(value) => {
                                setCareer((prev) => {
                                    prev.status = value.value
                                    return { ...prev }
                                })
                            }} />
                            <div className={typo.caption}>NOME</div>
                            <TextInput type="text" value={career?.name} placeholder="Nome" onKeyUp={(value) => {
                                setCareer((prev) => {
                                    prev.name = value
                                    prev.slug = value?.replace(/[^a-zA-Z0-9\s-]/g, '') // Rimuovi tutti i caratteri tranne lettere e numeri
                                        .replace(/\s+/g, '-') // Sostituisci spazi con trattini
                                        .toLowerCase()
                                        .trim();
                                    return { ...prev }
                                })
                            }} />
                            <div className={typo.caption}>SLUG</div>
                            <TextInput type="text" value={career?.slug} placeholder="Slug" onKeyUp={(value) => {
                                const newValue = value?.replace(/[^a-zA-Z0-9\s-]/g, '') // Rimuovi tutti i caratteri tranne lettere e numeri
                                    .replace(/\s+/g, '-') // Sostituisci spazi con trattini
                                    .toLowerCase()
                                    .trim(); // Converti tutto in minuscolo
                                setCareer((prev) => {
                                    prev.slug = newValue
                                    return { ...prev }
                                })
                            }} />
                            <div className={typo.caption}>DESCRIZIONE</div>
                            <textarea className={styles.description} value={career?.description} onChange={(e) => {
                                setCareer((prev) => {
                                    prev.description = e.target.value
                                    return { ...prev }
                                })
                            }} />
                            <div className={typo.caption}>SALARIO MEDIO</div>
                            <TextInput type="number" value={career?.salary_avg ?? '0'} placeholder="Salario" onKeyUp={(value) => {
                                setCareer((prev) => {
                                    prev.salary_avg = value
                                    return { ...prev }
                                })
                            }} />

                            {courses &&
                                <>
                                    <div className={typo.caption}>CORSO ASSOCIATO</div>
                                    <Select nullable selected={courses.find(c => c.id === career?.course_id)} options={courses} onSelect={async (value) => {
                                        setCareer((prev) => {
                                            prev.course_id = value?.id === -1 ? null : value?.id
                                            return { ...prev }
                                        })
                                        var course = null
                                        if (value.id !== -1) {
                                            course = await getCourse(value.id)
                                        }
                                        setCareerCourse(course)
                                    }} />
                                </>
                            }

                            <div className={typo.caption}>THUMBNAIL</div>
                            <TextInput disabled={career?.thumbnail?.startsWith('blob:')} type="text" value={career?.thumbnail} placeholder="Thumbnail URL" onKeyUp={(value) => {
                                setCareer((prev) => {
                                    prev.thumbnail = value ? value : ''
                                    return { ...prev }
                                })
                            }} />

                            <Dropzone
                                onDrop={(files) => {
                                    const thumbnailUrl = URL.createObjectURL(files[0])
                                    setThumbnail(files[0])
                                    setCareer((prev) => {
                                        prev.thumbnail = thumbnailUrl
                                        return { ...prev }
                                    })
                                }}
                                accept={
                                    {
                                        'image/jpeg': ['.jpg', '.jpeg'],
                                        "image/png": ['.png'],
                                        "image/svg+xml": ['.svg'],
                                        "image/webp": ['.webp']
                                    }
                                }
                            >
                                {({ getRootProps, getInputProps }) => (
                                    <section style={{ display: 'flex', width: '100%', padding: 0, margin: 0 }}>
                                        <div {...getRootProps()} style={{ display: 'flex', width: '100%' }}>
                                            <input {...getInputProps()} />
                                            <div className={styles.dropzone}>
                                                {career?.thumbnail &&
                                                    <div className={styles.thumbnailWrapper}>
                                                        <img src={career?.thumbnail} alt="" className={styles.thumbnail} />
                                                        <div className={styles.removeThumbnail} onClick={(e) => {
                                                            e.stopPropagation()
                                                            setThumbnail(null)
                                                            setCareer((prev) => {
                                                                prev.thumbnail = ''
                                                                return { ...prev }
                                                            })
                                                        }}>
                                                            <CloseIcon style={{ height: '16px', width: '16px' }} />
                                                        </div>
                                                    </div>
                                                }
                                                <div className={styles.dropzoneLabel}>
                                                    Trascina o seleziona un'immagine.
                                                </div>
                                                <Button
                                                    style={{ marginTop: '.5rem', padding: '0.6rem 2rem' }}
                                                    additionalClass={styles.selectFileButton}
                                                >
                                                    SCEGLI IMMAGINE
                                                </Button>
                                            </div>
                                        </div>
                                    </section>
                                )}
                            </Dropzone>

                            <div className={typo.caption}>VIDEO</div>
                            <TextInput disabled={video} type="text" value={career?.video_url} placeholder="Video URL" onKeyUp={(value) => {
                                setCareer((prev) => {
                                    prev.video_url = value ? value : ''
                                    return { ...prev }
                                })
                            }} />
                            <Dropzone
                                onDrop={(files) => {
                                    const videoUrl = URL.createObjectURL(files[0])
                                    setVideo(files[0])
                                    setCareer((prev) => {
                                        prev.video_url = videoUrl
                                        return { ...prev }
                                    })
                                }}
                                accept={
                                    {
                                        'video/mp4': ['.mp4', '.MP4'],
                                        "video/mov": ['.mov'],
                                        "video/m4v": ['.m4v'],
                                        "video/mpeg": ['.mpeg'],
                                    }
                                }
                            >
                                {({ getRootProps, getInputProps }) => (
                                    <section style={{ display: 'flex', width: '100%', padding: 0, margin: 0 }}>
                                        <div {...getRootProps()} style={{ display: 'flex', width: '100%' }}>
                                            <input {...getInputProps()} />
                                            <div className={styles.dropzone}>
                                                {video &&
                                                    <div className={styles.playerWrapper}>
                                                        <div className={styles.removeVideo} onClick={(e) => {
                                                            e.stopPropagation()
                                                            setVideo(null)
                                                            setCareer((prev) => {
                                                                prev.video_url = ""
                                                                return { ...prev }
                                                            })
                                                        }}>
                                                            <CloseIcon style={{ height: '16px', width: '16px' }} />
                                                        </div>
                                                        <ReactPlayer
                                                            url={career.video_url}
                                                            controls
                                                            width="100%"
                                                            height="100%"
                                                            className={styles.player}
                                                        />
                                                    </div>
                                                }
                                                <div className={styles.dropzoneLabel}>
                                                    Trascina o seleziona un video.
                                                </div>
                                                <Button
                                                    style={{ marginTop: '.5rem', padding: '0.6rem 2rem' }}
                                                    additionalClass={styles.selectFileButton}
                                                >
                                                    SCEGLI VIDEO
                                                </Button>
                                            </div>
                                        </div>
                                    </section>
                                )}
                            </Dropzone>



                            {tags &&
                                <>
                                    <div className={typo.caption}>TAG</div>
                                    <TagSelector
                                        selected={career.tags}
                                        options={tags}
                                        placeholder={"Cerca tag"}
                                        onAdd={(item) => {
                                            const newOptions = tags.filter(t => t.id !== item.id)
                                            setTags([...newOptions])

                                            setCareer((prev) => {
                                                if (!prev.tags.map(t => t.id).includes(item.id)) {
                                                    prev.tags.push(item)
                                                }
                                                return { ...prev }
                                            })
                                        }}
                                        onRemove={(item) => {
                                            const newOptions = [...tags]
                                            if (!newOptions.map(t => t.id).includes(item.id)) {
                                                newOptions.push(item)
                                            }
                                            setTags([...newOptions])

                                            setCareer((prev) => {
                                                prev.tags = prev.tags.filter(t => t.id !== item.id)
                                                return { ...prev }
                                            })
                                        }}
                                    />
                                </>
                            }
                            {partners && careerPartners &&
                                <>
                                    <div className={typo.caption}>PARTNER</div>
                                    <CheckbokSelect
                                        placeholder="Cerca partner"
                                        options={
                                            partners.map(p => {
                                                p._selected = careerPartners.map(cp => cp.id).includes(p.id)
                                                return p
                                            })}
                                        appearance="image"
                                        onSelect={(items) => {
                                            setCareerPartners(items)
                                        }} />
                                </>
                            }
                            <div className={styles.save}>
                                <Button disabled={saveDisabled || loading} fullWidth onClick={save}>
                                    <>
                                        {loading &&
                                            <Spinner />
                                        }
                                        {career?.status === 'draft' && "SALVA IN BOZZA"}
                                        {career?.status === 'public' && "PUBBLICA"}
                                        {career?.status === 'hidden' && "NASCONDI"}
                                    </>
                                </Button>
                            </div>
                        </>
                    }
                </div>
            </div>
            <AlertDialog
                open={alert.open}
                title={alert.title}
                text={alert.text}
                actions={alert.actions}
                status={alert.status}
                onClose={() => {
                    setAlert((prev) => {
                        prev.open = false
                        return { ...prev }
                    })
                }
                } />
        </HeaderFooterLayout>
    )
}

export default Career