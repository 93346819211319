import styles from "./ProgramTimeline.module.css"
import { Each } from "../common/Each"
import { useState } from "react"
import useWindowDimensions from "../common/hooks/useWindowDimensions"
import Collapsable from "./Collapsable"

const ProgramTimeline = ({ program, hideTimeline = false }) => {

    const { width } = useWindowDimensions()

    const [expanded, setExpanded] = useState(null)

    const handleExpand = (index) => {
        setExpanded(index)
    }

    return (
        <div className={styles.container}>
            <Each of={program} render={(module, index) => {
                return (
                    <>
                        <div className={styles.row}>
                            {!hideTimeline &&
                                <div className={styles.timeline}>

                                    <div className={styles.dashed} style={{ minHeight: '37px', visibility: index === 0 ? 'hidden' : 'visible' }} />

                                    <div className={styles.duration}>
                                        {`${module.duration ?? 0}${width > 768 ? ' ore' : 'h'}`}
                                    </div>
                                    <div className={styles.dashed} style={{ height: '100%', visibility: index === (program.length - 1) ? 'hidden' : 'visible' }} />

                                </div>
                            }
                            <div className={styles.accordions} style={{width: hideTimeline ? '100%' : '90%'}}>
                                <div className={styles.accordion}>
                                    {/** 
                                     * Remove expanded and onExpand if want to allow to expand all the accordions
                                    */}
                                    <Collapsable title={module.name} description={module.description} items={module.arguments} expanded={expanded === index} onExpand={() => { handleExpand(index) }} />
                                </div>
                            </div>
                        </div>
                    </>
                )
            }} />

        </div>
    )
}

export default ProgramTimeline
