import { useEffect, useState } from "react"
import { Each } from "../common/Each"
import styles from "./TeachersCarousel.module.css"
import TeacherPreviewCard from "./previews/TeacherPreviewCard"
import { motion, AnimatePresence } from "framer-motion"
import { palette } from "../common/constants"

const TeachersCarousel = ({ teachers, style }) => {

    const [elements, setElements] = useState([])
    const [selectedTeacher, setSelectedTeacher] = useState(null)
    const [animationStop, setAnimationStop] = useState(false)

    useEffect(() => {
        setAnimationStop(!!selectedTeacher)
    }, [selectedTeacher])

    useEffect(() => {
        if (teachers) {
            if (teachers.length > 1) {
                let newTeachers = repeatObjects(teachers, 3)
                for (let i = 0; i < newTeachers.length; i++) {
                    newTeachers[i].index = i
                }
                setElements(newTeachers)
            }
            else {
                let newTeachers = [...teachers]
                newTeachers = newTeachers.map((t, index) => {
                    t.index = index
                    return t
                })
                setElements(newTeachers)
            }
        }
    }, [teachers])

    function repeatObjects(arr, n) {
        let repeatedArray = [];
        for (let i = 0; i < n; i++) {
            let copies = arr.map(obj => ({ ...obj })); // Clona ogni oggetto
            repeatedArray.push(...copies);
        }
        return repeatedArray;
    }

    return (
        <>
            <AnimatePresence>
                {selectedTeacher && (
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0.2 }}
                        exit={{ opacity: 0 }}
                        className={styles.overlay} onClick={() => { setSelectedTeacher(null) }}>
                        <div className={styles.overlayInner}>
                            <motion.div layoutId={selectedTeacher.index} className={styles.selectedTeacher}>
                                <TeacherPreviewCard teacher={selectedTeacher} expanded onClose={() => { setSelectedTeacher(null) }} onClick={(e) => { e.stopPropagation() }} />
                            </motion.div>
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>
            <div className={styles.container} style={style}>
                <div className={styles.gradientLeft}></div>
                <div className={`${styles.track} ${elements.length > 1 ? styles.animated : ''} ${animationStop ? styles.stopped : ''}`}>
                    <Each of={elements} render={(e, index) => {
                        const colorIndex = index % palette.length; // Calcola l'indice del colore utilizzando l'operatore modulo
                        e.color = palette[colorIndex];
                        return (
                            <motion.div layoutId={e.index} className={`${styles.card} ${elements.length > 1 ? styles.animated : ''}`} onClick={() => { setSelectedTeacher(e) }}>
                                {!(selectedTeacher?.index === index) &&
                                    <TeacherPreviewCard teacher={e} />
                                }
                            </motion.div>
                        )
                    }} />
                </div>
                <div className={styles.gradientRight}></div>
            </div>
        </>

    )

}

export default TeachersCarousel