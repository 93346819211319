import { useEffect, useRef, useState } from "react"
import styles from "./MeatBallsMenu.module.css"
import { Each } from "../common/Each"
import { ReactComponent as MenuIcon } from "../assets/images/icons/ic-menu.svg"
import useClickOutside from "../common/hooks/useClickOutside"

const MeatBallsMenu = ({ open = false, onChange = () => { }, disabled = false, actions = [] }) => {
  const [_open, setOpen] = useState(open)
  const ref = useRef(null)

  useEffect(() => {
    setOpen(open)
  }, [open])

  useEffect(() => {
    onChange(_open)
  }, [_open])

  useClickOutside(ref, () => setOpen(false))

  return (
    <>
      <div ref={ref} className={styles.container} onClick={(e) => {
        e.stopPropagation()
        if (!disabled) {
          setOpen((o) => !o)
        }
      }}
        style={{ opacity: disabled ? "0.5" : "1", cursor: disabled ? "default" : "pointer" }}
      >
        <MenuIcon />
        <div className={`${styles.actions} ${_open ? styles.open : styles.closed}`}>
          <Each
            of={actions}
            render={(action) => (
              <div className={styles.action} onClick={(e) => {
                e.stopPropagation()
                if (action.onClick) {
                  action.onClick()
                  setOpen(false)
                }
              }}
                style={{ ...action.style ?? {} }}
              >
                {action.icon && <action.icon className={styles.icon} />}
                <div className={styles.label}>{action.label} </div>
              </div>
            )}
          />
        </div>
      </div>
    </>

  )
}


export default MeatBallsMenu
