import Markdown from 'react-markdown'
import ReactPlayer from "react-player"
import { Each } from "../../common/Each"
import typo from "../../typography.module.css"
import styles from "./MacroSectionPreview.module.css"

const MacroSectionPreview = ({ section, showPositions = true }) => {

    return (
        <div className={styles.container} id={section.id}>
            <div className={typo.subtitle}>{showPositions ? `${section.position}. ` : ''}{section.name}</div>
            <Each of={section.rows} render={(row) => {
                return (
                    <div className={styles.row} style={row.style}>
                        {row.type === 'text' &&
                            <div className={`${typo.body} ${styles.text}`}>
                                <Markdown>{row.content}</Markdown>
                            </div>
                        }
                        {row.type === 'image' && 
                            <img style={{display: 'flex', width: '100%'}} src={row.content} alt="" />
                        }
                        {row.type === 'columns' &&
                            <Each of={row.content} render={(column) => {
                                return (
                                    <div className={styles.column} style={{ width: `${100 / row.content.length}%`, ...column.style }}>
                                        {column.type === 'text' &&
                                            <div className={`${typo.body} ${styles.text}`}>
                                                <Markdown>{column.content}</Markdown>
                                            </div>
                                        }
                                        {column.type === 'image' &&
                                            <img src={column.content} alt="" />
                                        }
                                        {column.type === 'video' &&
                                            <div className={styles.playerWrapper}>
                                                <ReactPlayer
                                                    url={column.content}
                                                    width="100%"
                                                    height="100%"
                                                    className={styles.player}
                                                />
                                            </div>
                                        }
                                    </div>
                                )
                            }} />
                        }
                    </div>
                )
            }} />
        </div>
    )

}

export default MacroSectionPreview
