import { useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import { useNavigate, useParams } from "react-router-dom";
import TextareaAutosize from 'react-textarea-autosize';
import api from "../api";
import { ReactComponent as ClockIcon } from "../assets/images/icons/ic-clock.svg";
import { ReactComponent as CloseIcon } from "../assets/images/icons/ic-close.svg";
import { ReactComponent as ModulePlaceholder } from "../assets/images/illustrations/il-module-placeholder.svg";
import { CourseType, TagType } from "../common/constants";
import { Each } from "../common/Each";
import Back from "../components/Back";
import Button from "../components/Button";
import Card from "../components/cards/Card";
import StudentCard from "../components/cards/StudentCard";
import AlertDialog from "../components/dialogs/AlertDialog";
import HeaderFooterLayout from "../components/layouts/HeaderFooterLayout";
import Select from "../components/Select";
import Tag from "../components/Tag";
import TagSelector from "../components/TagSelector";
import TextInput from "../components/TextInput";
import { DialogStatus } from "../enums";
import typo from "../typography.module.css";
import styles from "./EditionEditor.module.css";

const EditionEditor = () => {

    const navigate = useNavigate()
    const [edition, setEdition] = useState(null)
    const { courseId, editionId } = useParams()
    const [course, setCourse] = useState(null)
    const [tags, setTags] = useState(null)
    const [thumbnail, setThumbnail] = useState(null)
    const [alert, setAlert] = useState({ open: false, title: '', text: '', actions: [], status: DialogStatus.Default })
    const [loading, setLoading] = useState(false)
    const [saveDisabled, setSaveDisabled] = useState(true)

    const [status, setStatus] = useState(
        [
            { id: 0, name: 'Bozza', value: 'draft' },
            { id: 1, name: 'Pubblico', value: 'public' },
            { id: 2, name: 'Nascosto', value: 'hidden' }
        ]
    )

    const initializeStatus = (status) => {
        switch (status) {
            case 'draft':
                setStatus([
                    { id: 0, name: 'Bozza', value: 'draft' },
                    { id: 1, name: 'Pubblico', value: 'public' },
                ])
                break
            case 'public':
                setStatus([
                    { id: 1, name: 'Pubblico', value: 'public' },
                    { id: 2, name: 'Nascosto', value: 'hidden' }
                ])
                break
            case 'hidden':
                setStatus([
                    { id: 1, name: 'Pubblico', value: 'public' },
                    { id: 2, name: 'Nascosto', value: 'hidden' }
                ])
                break
            default: return
        }
    }

    useEffect(() => {
        const getCourse = async () => {
            try {
                const course = await api.get(`/admin/courses/${courseId}`)
                setCourse(course)
            }
            catch (e) {
                console.error(e)
            }
        }

        if (courseId) {
            getCourse()
        }
    }, [courseId])

    useEffect(() => {
        const getEdition = async (edition_id) => {
            try {
                let edition = await api.get(`/admin/editions/${edition_id}`)
                setEdition(edition)
                initializeStatus(edition.status)
            }
            catch (e) {
                console.error(e)
            }
        }

        if (editionId && editionId !== 'create') {
            getEdition(editionId)
        }

        if (editionId && editionId === 'create') {
            setEdition({
                name: '',
                description: '',
                limit: 30,
                expired_at: new Date().toISOString(),
                thumbnail: null,
                status: 'draft',
                tags: [],
                students: [],
                modules: []
            })
        }
    }, [editionId])

    useEffect(() => {
        if (course && editionId === 'create') {
            setEdition((prev) => {
                prev.thumbnail = course.thumbnail
                return { ...prev }
            })
        }
    }, [course, editionId])

    useEffect(() => {
        const getTags = async () => {
            try {
                let tags = await api.get("/admin/tags?type=all")
                tags = tags.filter(t => {
                    return !edition.tags.map(t => t.id).includes(t.id)
                })
                setTags(tags)
            }
            catch (e) {
                console.error(e)
            }
        }
        if (!tags && edition) {
            getTags()
        }
        if (edition) {
            if (edition.status === 'public') {
                setSaveDisabled(edition.name.trim() === '' || edition.description.trim() === '' || (edition.thumbnail.trim() === '' && thumbnail === null))
            }
            else {
                setSaveDisabled(edition.name.trim() === '')
            }
        }
    }, [edition])

    const create = async () => {
        setLoading(true)
        try {
            let form = new FormData()
            form.append('course_id', course.id)
            form.append('name', edition.name)
            form.append('description', edition.description)
            form.append('expired_at', edition.expired_at)
            form.append('limit', edition.limit)
            form.append('status', edition.status)
            if (thumbnail) {
                form.append('thumbnailFile', thumbnail)
            }
            form.append('thumbnail', edition.thumbnail)
            form.append('tags', JSON.stringify(edition.tags.map(t => t.id)))

            const created = await api.post(`/admin/editions`, form, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })

            setEdition(created)
            initializeStatus(created.status)

            setAlert({
                open: true,
                title: 'Operazione Completata',
                text: 'I dati sono stati aggiornati con successo',
                status: DialogStatus.Success,
                actions: [
                    {
                        label: 'CHIUDI',
                        onClick: () => {
                            setAlert((prev) => {
                                prev.open = false
                                return { ...prev }
                            })
                            navigate(`/courses/${courseId}/editions/${created.id}`)
                        }
                    }
                ]
            })
        }
        catch (e) {
            console.error(e)
            setAlert({
                open: true,
                title: 'Operazione Fallita',
                text: e.detail,
                status: DialogStatus.Error,
                actions: [
                    {
                        label: 'OK',
                        onClick: () => {
                            setAlert((prev) => {
                                prev.open = false
                                return { ...prev }
                            })
                        }
                    }
                ]
            })
        }
        setLoading(false)
    }

    const update = async () => {
        setLoading(true)
        try {
            let form = new FormData()
            form.append('name', edition.name)
            form.append('description', edition.description)
            form.append('expired_at', edition.expired_at)
            form.append('limit', edition.limit)
            form.append('status', edition.status)
            if (thumbnail) {
                form.append('thumbnailFile', thumbnail)
            }
            form.append('thumbnail', edition.thumbnail)
            form.append('tags', JSON.stringify(edition.tags.map(t => t.id)))

            const updated = await api.put(`/admin/editions/${edition.id}`, form, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })

            setEdition(updated)
            initializeStatus(updated.status)

            setAlert({
                open: true,
                title: 'Operazione Completata',
                text: 'I dati sono stati aggiornati con successo',
                status: DialogStatus.Success,
                actions: [
                    {
                        label: 'CHIUDI',
                        onClick: () => {
                            setAlert((prev) => {
                                prev.open = false
                                return { ...prev }
                            })
                        }
                    }
                ]
            })
        }
        catch (e) {
            console.error(e)
            setAlert({
                open: true,
                title: 'Operazione Fallita',
                text: e.detail,
                status: DialogStatus.Error,
                actions: [
                    {
                        label: 'OK',
                        onClick: () => {
                            setAlert((prev) => {
                                prev.open = false
                                return { ...prev }
                            })
                        }
                    }
                ]
            })
        }
        setLoading(false)
    }

    const save = async () => {
        if (editionId === 'create') {
            await create()
        }
        else {
            await update()
        }
    }

    return (
        <HeaderFooterLayout hideFooter>
            <div className={styles.container}>
                {edition &&
                    <div className={styles.section}>
                        <div className={styles.sectionInner}>
                            <Back onClick={() => { navigate(`/courses/${courseId}`) }} />
                            {course && edition &&
                                <div className={`${typo.title} ${styles.navigation}`}>
                                    <div className={styles.clickable} onClick={() => {
                                        navigate(`/courses/${courseId}`)
                                    }}>{course.name}</div>
                                    <div>{">"}</div>
                                    <div>{edition.name}</div>
                                </div>
                            }
                            <Card>
                                <div className={styles.cardInner}>
                                    <div className={styles.row}>
                                        <div className={styles.column}>
                                            <div className={styles.columnInner}>
                                                <div className={typo.caption}>NOME</div>
                                                <TextInput type="text" value={edition.name} placeholder="Nome" onKeyUp={(value) => {
                                                    setEdition((prev) => {
                                                        prev.name = value
                                                        return { ...prev }
                                                    })
                                                }} />
                                            </div>

                                            <div className={styles.columnInner}>
                                                <div className={typo.caption}>DESCRIZIONE</div>
                                                <TextareaAutosize
                                                    value={edition.description}
                                                    minRows={5}
                                                    maxRows={10}
                                                    type="text"
                                                    className={styles.textArea}
                                                    placeholder="Descrizione"
                                                    onChange={(e) => {
                                                        const { value } = e.target
                                                        setEdition((prev) => {
                                                            prev.description = value
                                                            return { ...prev }
                                                        })
                                                    }} />
                                            </div>

                                            <div className={styles.columnInner}>
                                                <div className={typo.caption}>STATO</div>
                                                <Select style={{ height: 'fit-content' }} selected={status.find(s => s.value === edition.status)} options={status} onSelect={(value) => {
                                                    setEdition((prev) => {
                                                        prev.status = value.value
                                                        return { ...prev }
                                                    })
                                                }} />
                                            </div>

                                            <div className={styles.columnInner}>
                                                <div className={typo.caption}>LIMITE STUDENTI</div>
                                                <TextInput value={edition.limit} placeholder="Limite Studenti" type="number" onKeyUp={(value) => {
                                                    setEdition((prev) => {
                                                        prev.limit = value
                                                        return { ...prev }
                                                    })
                                                }} />
                                            </div>

                                            <div className={styles.columnInner}>
                                                <div className={typo.caption}>SCADENZA ISCRIZIONI</div>
                                                <TextInput
                                                    type="date"
                                                    value={new Date(edition.expired_at)}
                                                    defaultValue={edition.expired_at ? new Date(edition.expired_at) : null}
                                                    placeholder="Scadenza Iscrizioni"
                                                    onKeyUp={(d) => {
                                                        setEdition((prev) => {
                                                            prev.expired_at = d.toISOString()
                                                            return { ...prev }
                                                        })
                                                    }}
                                                />
                                            </div>

                                            <div className={styles.columnInner}>
                                                <div className={typo.caption}>TAG</div>
                                                {tags &&
                                                    <TagSelector
                                                        style={{ fontSize: '1rem' }}
                                                        selected={edition.tags.filter(t => t.type === TagType.General)}
                                                        options={tags.filter(t => t.type === TagType.General)}
                                                        placeholder={"Cerca tag"}
                                                        onAdd={(item) => {
                                                            const newOptions = tags.filter(t => t.id !== item.id)
                                                            setTags([...newOptions])

                                                            setEdition((prev) => {
                                                                if (!prev.tags.map(t => t.id).includes(item.id)) {
                                                                    prev.tags.push(item)
                                                                }
                                                                return { ...prev }
                                                            })
                                                        }}
                                                        onRemove={(item) => {
                                                            const newOptions = [...tags]
                                                            if (!newOptions.map(t => t.id).includes(item.id)) {
                                                                newOptions.push(item)
                                                            }
                                                            setTags([...newOptions])

                                                            setEdition((prev) => {
                                                                prev.tags = prev.tags.filter(t => t.id !== item.id)
                                                                return { ...prev }
                                                            })
                                                        }}
                                                    />
                                                }
                                            </div>
                                        </div>
                                        <div className={styles.column}>
                                            <div className={styles.columnInner}>
                                                <div className={typo.caption}>THUMBNAIL</div>
                                                <TextInput disabled={edition?.thumbnail?.startsWith('blob:')} type="text" value={edition?.thumbnail} placeholder="Thumbnail URL" onKeyUp={(value) => {
                                                    setEdition((prev) => {
                                                        prev.thumbnail = value ? value : ''
                                                        return { ...prev }
                                                    })
                                                }} />

                                                <Dropzone
                                                    onDrop={(files) => {
                                                        const thumbnailUrl = URL.createObjectURL(files[0])
                                                        setThumbnail(files[0])
                                                        setEdition((prev) => {
                                                            prev.thumbnail = thumbnailUrl
                                                            return { ...prev }
                                                        })
                                                    }}
                                                    accept={
                                                        {
                                                            'image/jpeg': ['.jpg', '.jpeg'],
                                                            "image/png": ['.png'],
                                                            "image/svg+xml": ['.svg'],
                                                            "image/webp": ['.webp']
                                                        }
                                                    }
                                                >
                                                    {({ getRootProps, getInputProps }) => (
                                                        <section style={{ display: 'flex', width: '100%', padding: 0, margin: 0 }}>
                                                            <div {...getRootProps()} style={{ display: 'flex', width: '100%' }}>
                                                                <input {...getInputProps()} />
                                                                <div className={styles.dropzone}>
                                                                    {edition?.thumbnail &&
                                                                        <div className={styles.thumbnailWrapper}>
                                                                            <img src={edition?.thumbnail} alt="" className={styles.thumbnail} />
                                                                            <div className={styles.removeThumbnail} onClick={(e) => {
                                                                                e.stopPropagation()
                                                                                setThumbnail(null)
                                                                                setEdition((prev) => {
                                                                                    prev.thumbnail = ''
                                                                                    return { ...prev }
                                                                                })
                                                                            }}>
                                                                                <CloseIcon style={{ height: '16px', width: '16px' }} />
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    <div className={styles.dropzoneLabel}>
                                                                        Trascina o seleziona un'immagine.
                                                                    </div>
                                                                    <Button
                                                                        style={{ marginTop: '.5rem', padding: '0.6rem 2rem' }}
                                                                        accentColor={"var(--tertiary)"}
                                                                    >
                                                                        SCEGLI IMMAGINE
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        </section>
                                                    )}
                                                </Dropzone>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Card>

                            {editionId !== 'create' &&
                                <>
                                    <div className={`${typo.subtitle} ${styles.modulesHeader}`}>
                                        Moduli • {edition.modules.length}
                                        <Button onClick={() => {
                                            if (course.type === CourseType.Masterclass) {
                                                if (edition.modules.length === 0) {
                                                    navigate(`/courses/${courseId}/editions/${editionId}/modules/create`)
                                                }
                                                else {
                                                    setAlert({
                                                        open: true,
                                                        text: "Le masterclass possono contenere al massimo un modulo.",
                                                        status: DialogStatus.Error,
                                                        actions: [
                                                            {
                                                                label: 'OK',
                                                                onClick: () => {
                                                                    setAlert((prev) => {
                                                                        prev.open = false
                                                                        return { ...prev }
                                                                    })
                                                                }
                                                            }
                                                        ]
                                                    })
                                                }
                                            }

                                        }}>
                                            AGGIUNGI
                                        </Button>
                                    </div>
                                    <div className={styles.modules}>
                                        <Each of={edition.modules} render={(module) => {
                                            return (
                                                <div className={styles.module} onClick={() => {
                                                    navigate(`/courses/${courseId}/editions/${editionId}/modules/${module.id}`)
                                                }}>
                                                    <div className={styles.moduleDuration}>
                                                        <ClockIcon />
                                                        {module.duration}h
                                                    </div>
                                                    {module.thumbnail &&
                                                        <img src={module.thumbnail} className={styles.moduleThumbnail} alt="" />
                                                    }
                                                    {!module.thumbnail &&
                                                        <ModulePlaceholder className={styles.moduleThumbnail} />
                                                    }
                                                    <div className={styles.moduleName}>
                                                        {module.id} - {module.name}
                                                    </div>
                                                    <div className={styles.moduleDescription}>
                                                        {module.description}
                                                    </div>
                                                    <div className={styles.moduleTags}>
                                                        <Each of={module.tags} render={(tag) => {
                                                            return (
                                                                <Tag tag={tag} />
                                                            )
                                                        }} />
                                                    </div>
                                                </div>
                                            )
                                        }} />
                                    </div>

                                    <div className={typo.subtitle}>
                                        Studenti • {edition.students.length}
                                    </div>
                                    <div className={styles.students}>
                                        <Each of={edition.students} render={(student) => {
                                            return (
                                                <div className={styles.student}>
                                                    <StudentCard student={student} />
                                                </div>
                                            )
                                        }} />
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                }
            </div >
            <div className={styles.bottomBar}>
                <Button
                    disabled={loading || saveDisabled}
                    onClick={() => {
                        save()
                    }}>
                    {editionId === 'create' ? "SALVA" : "SALVA MODIFICHE"}
                </Button>
            </div>
            <AlertDialog
                open={alert.open}
                title={alert.title}
                text={alert.text}
                actions={alert.actions}
                status={alert.status}
                onClose={() => {
                    setAlert((prev) => {
                        prev.open = false
                        return { ...prev }
                    })
                }
                } />
        </HeaderFooterLayout >
    )

}

export default EditionEditor