import { ReactComponent as ChevronIcon } from "../../assets/images/icons/ic-chevron.svg"
import { AccountStatus, Role } from "../../common/constants"
import { Each } from "../../common/Each"
import Skeleton from "../Skeleton"
import Tag from "../Tag"
import styles from "./TeacherCard.module.css"
import { ReactComponent as CheckIcon } from "../../assets/images/icons/ic-check.svg"
import UserPlaceholder from "../../assets/images/placeholders/user-placeholder.png"
import { useState } from "react"

const TeacherCard = ({ teacher, loading = false, onClick = () => { }, style, options = { status: true, chevron: true, tags: true } }) => {

    const [picture, setPicture] = useState(teacher?.picture ?? UserPlaceholder)

    return (
        <>
            {
                loading &&
                <div className={`${styles.container}`} onClick={onClick}>
                    <div className={styles.info}>
                        <div className={styles.left}>
                            <Skeleton type="circle" width="72px" height="72px" />
                            <div className={styles.infoInner}>
                                <Skeleton type="rect" width="72px" height="20px" borderRadius={"8px"} />
                                <Skeleton type="rect" width="144px" height="14px" borderRadius={"8px"} />
                                <div className={styles.tags}>
                                    <Each
                                        of={[130, 44, 122]}
                                        render={(tagWidth) => (
                                            <Skeleton type="rect" width={`${tagWidth}px`} height="20px" borderRadius="8px" />
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                        <ChevronIcon className={styles.chevron} />
                    </div>
                </div>
            }
            {
                !loading &&
                <div className={`${styles.container}`} onClick={onClick}>
                    <div className={styles.info}>
                        <div className={styles.left}>
                            <img src={picture} className={styles.avatar} style={{
                                borderColor: teacher.color ??
                                    teacher.role === Role.Teacher ? "var(--tertiary)" : "var(--sf-purple)"
                            }} alt="teacher" onError={() => setPicture(UserPlaceholder)} />
                            <div className={styles.infoInner}>
                                <div className={styles.name}>{teacher.name} {teacher.surname}</div>
                                <div className={styles.qualification}>{teacher.qualification}</div>
                                {options.tags &&
                                    <div className={styles.tags}>
                                        <Each of={teacher.tags} render={(tag) => {
                                            return (<Tag tag={tag} />)
                                        }} />
                                    </div>
                                }
                            </div>
                        </div>
                        <div className={styles.right}>
                            {options.status &&
                                <>
                                    {
                                        teacher.status === AccountStatus.Active &&
                                        <div className={`${styles.status} ${styles.active}`}>ATTIVO</div>
                                    }
                                    {
                                        teacher.status === AccountStatus.Suspended &&
                                        <div className={`${styles.status} ${styles.suspended}`}>SOSPESO</div>
                                    }
                                    {options.chevron &&
                                        <ChevronIcon className={styles.chevron} />
                                    }
                                </>
                            }
                            {options.selected &&
                                <div className={styles.checkIcon}>
                                    <CheckIcon />
                                </div>
                            }
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default TeacherCard
