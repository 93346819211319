import { useCallback, useEffect, useState } from "react"
import styles from "./Section.module.css"
import TextInput from "../TextInput"
import Card from "../cards/Card"
import { Each } from "../../common/Each"
import { getDefaultRow } from "../../utils"
import Row from "./Row"
import { ReactComponent as CloseIcon } from "../../assets/images/icons/ic-close.svg"
import { RowType } from "../../common/constants"

const Section = ({
  name,
  position,
  rows,
  onDelete = () => { },
  onChange = () => { },
  onSelection = () => { },
  selection = null
}) => {

  const [_name, setName] = useState(name)
  const [_position, setPosition] = useState(position)
  const [_rows, setRows] = useState(rows)

  const addRow = useCallback(() => {
    setRows(r => {
      return [...r, getDefaultRow()]
    })

  }, [])

  const onRowChange = useCallback(({ rowIndex, columnIndex, content, type }) => {
    setRows(r => {
      r[rowIndex] = { content, type }
      return [...r]
    })
  }, [])

  useEffect(() => {
    onChange(_name, _rows)
  }, [_name, _rows])

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        Sezione {position}
        {
          position > 1 &&
          <button className={styles.closeButton} onClick={(e) => {
            e.stopPropagation()
            onDelete(position - 1)
          }}>
            <CloseIcon />
          </button>
        }
      </div>
      <Card hover style={{ padding: ".5rem 1rem", marginTop: ".5rem" }} title={`Titolo`}>
        <TextInput
          type={"text"}
          value={_name}
          onKeyUp={(value) => {
            setName(value)
          }}
          style={{
            padding: 0, border: "none", backgroundColor: "transparent",
            fontSize: "1rem", fontWeight: 700, width: "100%",
            borderRadius: 0
          }} />
      </Card>

      <div className={styles.rows}>
        <Each
          of={_rows}
          render={(row, rowIndex) => (
            <Row
              {...row}
              selection={selection}
              index={rowIndex}
              onSelection={onSelection}
              onChange={({ content, type, columnIndex }) => onRowChange({ content, type, rowIndex, columnIndex })}
              onDeleteRow={(i) => {
                setRows((r) => {
                  r.splice(i, 1)
                  return [...r]
                })
              }}
            />
          )}
        />
      </div>
      <div className={styles.addRow} onClick={addRow}>
        + Aggiungi riga
      </div>

    </div>
  )

}

export default Section
