import { TagType } from "../common/constants"
import styles from "./Tag.module.css"

const Tag = ({ tag, Icon = null, onClickIcon = () => { }, onClick, style }) => {

    return (
        <>
            {tag.type === TagType.General &&
                <div
                    className={styles.container}
                    style={{
                        backgroundColor: `${tag.color}4D`,
                        color: tag.color,
                        cursor: onClick ? 'pointer' : 'default',
                        ...style
                    }}
                    onClick={onClick}
                >
                    {Icon && <Icon className={styles.icon} onClick={onClickIcon} />}
                    {tag.name}
                </div>
            }
            {tag.type === TagType.Feature &&
                <div className={styles.feature}
                    style={{
                        cursor: onClick ? 'pointer' : 'default',
                        ...style
                    }}
                    onClick={onClick}>
                    {Icon && <Icon className={styles.featureIcon} onClick={onClickIcon} />}
                    {
                        tag.icon?.startsWith("<svg") &&
                        <div
                            style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "20px", height: "20px" }}
                            dangerouslySetInnerHTML={{ __html: tag.icon }} />
                    }
                    {
                        tag.icon && !tag.icon?.startsWith("<svg") &&
                        <img src={tag.icon} alt={""} />
                    }
                    {tag.name}
                </div>
            }
        </>
    )
}

export default Tag
