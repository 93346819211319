import React from "react";

const MainContext = React.createContext(
  {
    user: null,
    setUser: () => { },
    isLogging: null,
    loginDialog: null,
    setLoginDialog: () => { },
    notificationPermissions: null,
    setNotificationPermissions: () => { },
    notificationBanner: null,
    setNotificationBanner: () => { },
    notifications: null,
    setNotifications: () => { },
    unreadMessages: null,
    setUnreadMessages: () => { },
  }
);

export default MainContext;
