import Footer from "../layouts/Footer"
import style from "./FooterLayout.module.css"

const FooterLayout = ({children}) => {

    return(
        <div className={style.container}>
            {children}
            <Footer></Footer>
        </div>
    )
}

export default FooterLayout