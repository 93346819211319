import { useEffect, useState } from "react"
import { Each } from "../common/Each"
import { palette } from "../common/constants"
import styles from "./ReviewsCarousel.module.css"
import ReviewCard from "./cards/ReviewCard"

const ReviewsCarousel = ({ reviews, style }) => {

    const [elements, setElements] = useState([])

    useEffect(() => {
        if (reviews) {
            if (reviews.length > 1) {
                let newTeachers = repeatObjects(reviews, 3)
                for (let i = 0; i < newTeachers.length; i++) {
                    newTeachers[i].index = i
                }
                setElements(newTeachers)
            }
            else {
                let newTeachers = [...reviews]
                newTeachers = newTeachers.map((t, index) => {
                    t.index = index
                    return t
                })
                setElements(newTeachers)
            }
        }
    }, [reviews])

    function repeatObjects(arr, n) {
        let repeatedArray = [];
        for (let i = 0; i < n; i++) {
            let copies = arr.map(obj => ({ ...obj })); // Clona ogni oggetto
            repeatedArray.push(...copies);
        }
        return repeatedArray;
    }

    return (
            <div className={styles.container} style={style}>
                <div className={styles.gradientLeft}></div>
                <div className={`${styles.track} ${elements.length > 1 ? styles.animated : ''}`}>
                    <Each of={elements} render={(e, index) => {
                        const colorIndex = index % palette.length; // Calcola l'indice del colore utilizzando l'operatore modulo
                        e.color = palette[colorIndex];
                        return (
                            <div layoutId={e.index} className={`${styles.card} ${elements.length > 1 ? styles.animated : ''}`} >
                                <ReviewCard review={e}/>
                            </div>

                        )
                    }} />
                </div>
                <div className={styles.gradientRight}></div>
            </div>
    )

}

export default ReviewsCarousel