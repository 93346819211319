import { useTranslation } from "react-i18next"
import { CourseType } from "../common/constants"
import styles from "./CourseBadge.module.css"

const CourseBadge = ({ type, style }) => {

    const { t } = useTranslation()

    return (
        <>
            {type === CourseType.Masterclass &&
                <div className={`${styles.courseType} ${styles.masterclass}`} style={style}>{t(`courses.types.${type}`).toUpperCase()}</div>

            }
            {type === CourseType.Professional &&
                <div className={`${styles.courseType} ${styles.professional}`} style={style}>{t(`courses.types.${type}`).toUpperCase()}</div>

            }
        </>
    )

}

export default CourseBadge