import HeaderFooterLayout from "../components/layouts/HeaderFooterLayout"
import styles from "./Macros.module.css"
import typo from "../typography.module.css"
import { useTranslation } from "react-i18next"
import { useEffect, useState } from "react"
import api from "../api"
import Card from "../components/cards/Card"
import { Each } from "../common/Each"
import { ReactComponent as ChevronIcon } from "../assets/images/icons/ic-chevron.svg"
import { useNavigate } from "react-router-dom"
import Skeleton from "../components/Skeleton"
import Button from "../components/Button"
import { ReactComponent as AddIcon } from "../assets/images/icons/ic-add-circle.svg"

const Macros = () => {

    const navigate = useNavigate()
    const { t } = useTranslation()
    const [macros, setMacros] = useState(null)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const getMacros = async () => {
            setLoading(true)
            try {
                let macros = await api.get("/admin/macros")
                setMacros(macros.sort((a, b) => { return a.name > b.name }))
            }
            catch (e) {
                console.error(e)
            }
            setLoading(false)
        }

        if (!macros) {
            getMacros()
        }
    }, [])

    const getStatusStyle = (status) => {
        switch (status) {
            case 'public':
                return styles.public
            case 'draft':
                return styles.draft
            case 'hidden':
                return styles.hidden
            default:
                return null
        }
    }

    return (
        <HeaderFooterLayout>
            <div className={styles.container}>
                <div className={styles.section}>
                    <div className={styles.sectionInner}>
                        <div className={typo.title} style={{ display: 'flex', justifyContent: 'space-between' }}>
                            Aree di interesse
                            <Button style={{ padding: '0 1rem' }} onClick={() => { navigate('/macros/create') }}>
                                AGGIUNGI
                                <AddIcon />
                            </Button></div>

                        <Card>
                            <div className={styles.macros}>
                                {loading &&
                                    <Each of={Array(7).fill('')} render={(i) => {
                                        return (
                                            <div className={styles.skeletonMacro}>
                                                <Skeleton type="rect" width={50} height={50} borderRadius="12px" />
                                                <div className={styles.skeletonInfo}>
                                                    <Skeleton type="rect" width={150} height={20} borderRadius="4px" />
                                                    <Skeleton type="rect" width={300} height={15} borderRadius="4px" />
                                                </div>
                                                <Skeleton type="rect" width={60} height={30} borderRadius="8px" />
                                                <ChevronIcon className={styles.chevron} />
                                            </div>
                                        )
                                    }} />
                                }
                                {!loading &&
                                    <Each of={macros} render={(macro) => {
                                        return (
                                            <div className={styles.macro} onClick={() => {
                                                navigate(`/macros/${macro.id}`)
                                            }}>
                                                <div className={styles.thumbnail} style={{ background: macro.background }} />
                                                <div className={styles.info}>
                                                    <div className={styles.title}>{macro.name}</div>
                                                    <div className={styles.description}>{macro.description}</div>
                                                </div>
                                                <div className={`${styles.status} ${getStatusStyle(macro.status)}`}>{macro.status}</div>
                                                <ChevronIcon className={styles.chevron} />
                                            </div>
                                        )
                                    }} />
                                }
                            </div>
                        </Card>
                    </div>
                </div>
            </div>
        </HeaderFooterLayout>
    )

}

export default Macros
