// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, setPersistence, browserLocalPersistence } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCCKSPLhTEUhaY89sSPQQR2wJUfAT5KUVA",
  authDomain: "starting-finance-edu-dev.firebaseapp.com",
  projectId: "starting-finance-edu-dev",
  storageBucket: "starting-finance-edu-dev.appspot.com",
  messagingSenderId: "760526621894",
  appId: "1:760526621894:web:f6f4b88019e42de8319bc7",
  measurementId: "G-XXPH5C2VS7"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig, 'starting-finance-dev');
const auth = getAuth(app);
const analytics = getAnalytics(app);

await setPersistence(auth, browserLocalPersistence)

export { auth, analytics }
export default app
