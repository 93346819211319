import axios from "axios";
import { auth } from "./firebase"

const api = axios.create({
  baseURL: "https://beta.startingfinance.com/api",
  // baseURL: "http://localhost:8000",
  timeout: "30000"
})


api.interceptors.request.use(async (config) => {
  // Ottieni il token
  const tokenResult = await auth.currentUser.getIdTokenResult()
  const token = tokenResult.token

  // Aggiungi il token all'header Authorization
  config.headers.Authorization = `Bearer ${token}`;

  return config;
}, (error) => {
  // Gestisci errori nell'interceptor
  console.error(error)
  return Promise.reject(error);
});

api.interceptors.response.use(async (response) => {
  if (![200, 201].includes(response.status)) {
    return Promise.reject(response.data)
  }
  return response.data
}, (error) => {
  console.error(error)
  return Promise.reject(error.response.data)
})

export default api;
