import { palette } from "../common/constants";
import { Each } from "../common/Each";
import styles from "./TeachersBadge.module.css";

const TeachersBadge = ({
  teachers = [],
  pictureStyle = {},
  nameStyle = {},
  qualificationStyle = {},
  centered = false,
  showQualification = false,
}) => {
  return (
    <div className={centered ? styles.centered : styles.teachers}>
      {teachers.length === 1 && (
        <>
          <img
            className={styles.teacherPicture}
            src={teachers[0].picture}
            alt={`${teachers[0].surname}`}
            style={pictureStyle}
          />
          <div className={styles.teacherInfo}>
            <div className={styles.teacherName} style={nameStyle}>
              {teachers[0].name} {teachers[0].surname}
            </div>
            {teachers[0].qualification && showQualification && (
              <div
                className={styles.teacherQualification}
                style={qualificationStyle}
              >
                {teachers[0].qualification}
              </div>
            )}
          </div>
        </>
      )}
      {teachers.length > 1 && (
        <Each
          of={teachers}
          render={(teacher, index) => {
            return (
              <img
                className={styles.teacherPicture}
                style={{
                  zIndex: index,
                  border: `2px solid ${palette[index]}`,
                  transform: `translateX(${-20 * index}%)`,
                  ...pictureStyle,
                }}
                src={teacher.picture}
                alt={`${teacher.surname}`}
              />
            );
          }}
        />
      )}
    </div>
  );
};

export default TeachersBadge;
