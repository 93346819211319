import { useEffect, useMemo, useRef, useState } from "react"
import { ChromePicker } from "react-color"
import { Helmet, HelmetProvider } from "react-helmet-async"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import api from "../api"
import { ReactComponent as AddIcon } from "../assets/images/icons/ic-add-circle.svg"
import { ReactComponent as CloseIcon } from "../assets/images/icons/ic-close.svg"
import { ReactComponent as ColorIcon } from "../assets/images/icons/ic-color.svg"
import { ReactComponent as EditIcon } from "../assets/images/icons/ic-pencil.svg"
import { ReactComponent as DeleteIcon } from "../assets/images/icons/ic-delete.svg"
import { ButtonStatus, TagType } from "../common/constants"
import { Each } from "../common/Each"
import useClickOutside from "../common/hooks/useClickOutside"
import Button from "../components/Button"
import IconsDialog from "../components/dialogs/IconsDialog"
import HeaderFooterLayout from "../components/layouts/HeaderFooterLayout"
import Select from "../components/Select"
import Tag from "../components/Tag"
import TextInput from "../components/TextInput"
import typo from "../typography.module.css"
import styles from "./Settings.module.css"

const Settings = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const [tags, setTags] = useState([])
    const [tagId, setTagId] = useState(null)
    const [name, setName] = useState("")
    const [color, setColor] = useState("#000000")
    const [type, setType] = useState(TagType.General)
    const [icon, setIcon] = useState(null)
    const [iconColor, setIconColor] = useState("#2a2a2a99")
    const [openIconPicker, setOpenIconPicker] = useState(false)
    const [openColorPicker, setOpenColorPicker] = useState(false)
    const [openIconColorPicker, setOpenIconColorPicker] = useState(false)
    const [savingTag, setSavingTag] = useState(null)
    const [deletingTag, setDeletingTag] = useState(null)
    const [tagSavingTimeout, setTagSavingTimeout] = useState(null)

    const colorRef = useRef(null)
    const iconColorRef = useRef(null)

    useClickOutside(colorRef, () => setOpenColorPicker(false))
    useClickOutside(iconColorRef, () => setOpenIconColorPicker(false))

    const typeOptions = useMemo(() => {
        return [
            { id: 0, name: "Generico", value: TagType.General },
            { id: 1, name: "Feature", value: TagType.Feature }
        ]
    }, [])

    useEffect(() => {
        getTags()

        return () => {
            if (tagSavingTimeout) {
                clearTimeout(tagSavingTimeout)
            }
        }
    }, [])

    useEffect(() => {
        if (type === TagType.General) {
            setIcon(null)
        }
    }, [type])

    useEffect(() => {
        if (savingTag === ButtonStatus.Success || savingTag === ButtonStatus.Error) {
            setTagSavingTimeout(setTimeout(() => setSavingTag(null), 3000))
        }
    }, [savingTag])

    const tagIcon = useMemo(() => {
        if (!icon)
            return null

        if (icon.startsWith("<svg")) {
            return () => <div
                style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "20px", height: "20px" }}
                dangerouslySetInnerHTML={{ __html: icon.replace(/fill="#[^"]*"/g, `fill="${iconColor}"`).replace(/stroke="#[^"]*"/g, 'stroke="currentColor"') }} />
        }
        return () => <img src={icon} style={{ display: "flex", justifyContent: "center", alignItems: "center" }} />
    }, [icon, iconColor])

    const getTags = async () => {
        try {
            const tags = await api.get("/admin/tags?type=all")
            setTags(tags)
        } catch (e) {
            console.error(e)
        }
    }

    const saveTag = async () => {
        setSavingTag(ButtonStatus.Loading)
        try {
            const data = {
                name,
                icon: icon?.replace(/fill="#[^"]*"/g, `fill="${iconColor}"`).replace(/stroke="#[^"]*"/g, 'stroke="currentColor"'),
                color,
                type,
            }
            if (tagId) {
                await api.put(`/admin/tags/${tagId}`, data)
            } else {
                await api.post("/admin/tags", data)
            }

            getTags()
            setTagId(null)
            setType(TagType.General)
            setName("")
            setColor("#000000")
            setIcon(null)
            setSavingTag(ButtonStatus.Success)
        } catch (e) {
            console.error(e)
            setSavingTag(ButtonStatus.Error)
        }
    }

    const deleteTag = async () => {
        setDeletingTag(ButtonStatus.Loading)
        try {
            await api.delete(`/admin/tags/${tagId}`)

            getTags()
            setTagId(null)
            setType(TagType.General)
            setName("")
            setColor("#000000")
            setIcon(null)
            setDeletingTag(ButtonStatus.Success)
        } catch (e) {
            console.error(e)
            setDeletingTag(ButtonStatus.Error)
        }
    }

    return (
        <HeaderFooterLayout>
            <HelmetProvider>
                <Helmet>
                    <title>Impostazioni</title>
                </Helmet>
            </HelmetProvider>
            <div className={styles.container}>
                <div className={styles.section}>
                    <div className={styles.sectionInner}>
                        <div className={typo.title} style={{ display: 'flex', justifyContent: 'space-between' }}>Impostazioni</div>

                        <div className={styles.card}>
                            <div className={styles.tags}>
                                <div className={styles.title}>Tag</div>
                                <div className={styles.tagsContent}>
                                    <div className={styles.tagsType}>Generici</div>
                                    <div className={styles.tagsList}>
                                        <Each
                                            of={tags.filter(t => t.type === TagType.General).sort((a,b) => {
                                                return a.name > b.name
                                            })}
                                            render={(tag) => (
                                                <Tag tag={tag} onClick={() => {
                                                    setTagId(tag.id)
                                                    setName(tag.name)
                                                    setColor(tag.color)
                                                    setIcon(null)
                                                    setType(tag.type)
                                                }} />
                                            )}
                                        />
                                    </div>
                                </div>

                                <div className={styles.tagsContent}>
                                    <div className={styles.tagsType}>Feature</div>
                                    <div className={styles.tagsList}>
                                        <Each
                                            of={tags.filter(t => t.type === TagType.Feature)}
                                            render={(tag) => (
                                                <Tag tag={tag} onClick={() => {
                                                    setTagId(tag.id)
                                                    setName(tag.name)
                                                    setIcon(tag.icon)
                                                    setType(tag.type)
                                                    setColor("#000000")
                                                }} />
                                            )}
                                        />
                                    </div>
                                </div>
                                <div className={styles.tagsContent}>
                                    <div className={styles.tagsForm}>
                                        <div className={styles.row}>
                                            <Tag tag={{
                                                name,
                                                type,
                                                color: color,
                                            }}
                                                Icon={tagIcon}
                                            />
                                        </div>
                                        <div className={styles.row}>
                                            <div style={{ flexBasis: "50%" }}>
                                                <Select
                                                    selected={typeOptions.find(t => t.value === type)}
                                                    options={typeOptions}
                                                    onSelect={(tagType) => setType(tagType.value)}
                                                />

                                            </div>
                                            <div style={{ flexBasis: "50%" }}>
                                                <TextInput
                                                    type="text"
                                                    value={name}
                                                    placeholder="Nome"
                                                    onKeyUp={(v) => setName(v)} />
                                            </div>
                                            {
                                                type === TagType.General &&
                                                <div
                                                    onClick={() => setOpenColorPicker((p) => !p)}
                                                    className={styles.tagBackground}
                                                    style={{ backgroundColor: color }}>
                                                    <div ref={colorRef} className={openColorPicker ? `${styles.colorPicker} ${styles.open}` : styles.colorPicker} onClick={(e) => e.stopPropagation()}>
                                                        <ChromePicker disableAlpha color={color} onChange={(color) => setColor(color.hex)} />
                                                    </div>
                                                </div>
                                            }

                                            {
                                                !icon && type === TagType.Feature &&
                                                <div className={styles.addIcon}>
                                                    <Button
                                                        appearance="text"
                                                        style={{ display: "flex", gap: ".5rem", alignItems: "center", flexDirection: "row" }}
                                                        onClick={() => setOpenIconPicker(true)}
                                                    >
                                                        Aggiungi icona <AddIcon />
                                                    </Button>
                                                </div>
                                            }
                                        </div>

                                        {
                                            icon && type === TagType.Feature &&
                                            <div className={styles.row} style={{ justifyContent: "center" }}>
                                                <div className={styles.iconContainer}>
                                                    <div className={styles.icon}>
                                                        {
                                                            icon.startsWith("<svg") &&
                                                            <div className={styles.iconData}
                                                                dangerouslySetInnerHTML={{ __html: icon.replace(/fill="#[^"]*"/g, `fill="${iconColor}"`).replace(/stroke="#[^"]*"/g, 'stroke="currentColor"') }}
                                                            />
                                                        }
                                                        {
                                                            !icon.startsWith("<svg") &&
                                                            <img src={icon} className={styles.iconData} />
                                                        }
                                                        <div className={styles.iconActions}>
                                                            <CloseIcon
                                                                style={{ width: "24px", height: "24px", color: "var(--secondary)" }}
                                                                onClick={() => setIcon(null)}
                                                            />
                                                            <EditIcon style={{ color: "var(--primary)" }} onClick={() => setOpenIconPicker(true)} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <div className={styles.tagActions}>
                                        {
                                            tagId &&
                                            <Button
                                                inverse
                                                status={deletingTag}
                                                accentColor={"var(--secondary)"}
                                                onClick={deleteTag}
                                                disabled={!tagId}
                                            >
                                                ELIMINA {!deletingTag && <DeleteIcon style={{ width: "16px", height: "16px" }} />}
                                            </Button>
                                        }
                                        <Button
                                            status={savingTag}
                                            accentColor={"var(--tertiary)"}
                                            onClick={saveTag}
                                            disabled={!name}
                                        >
                                            {tagId === null ? <>AGGIUNGI {!savingTag && <AddIcon />}</> : <>MODIFICA {!savingTag && <EditIcon />}</>}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <IconsDialog
                open={openIconPicker}
                selected={icon}
                onClose={() => setOpenIconPicker(false)}
                onSelect={(icon) => {
                    setIcon(icon.data)
                    setOpenIconPicker(false)
                }} />

        </HeaderFooterLayout >
    )
}

export default Settings
