import { useCallback, useState } from "react"
import styles from "./Checkbox.module.css"

const Checkbox = ({ label = "", value = false, onChange = () => { }, style = {} }) => {

  const [_value, setValue] = useState(value)

  const handleChange = useCallback((e) => {
    const { checked } = e.target
    setValue(checked)
    onChange(checked)
  }, [])

  return (
    <div className={styles.container} style={style}>
      {
        label &&
        <div className={styles.label}>{label}</div>
      }
      <input type="checkbox" checked={_value} value={_value} onChange={handleChange} className={styles.input} />
    </div>
  )
}

export default Checkbox
