import { getMessaging, getToken, isSupported, onMessage } from 'firebase/messaging';
import { useEffect, useRef, useState } from 'react';
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.css";
import api from './api';
import MainContext from "./common/MainContext";
import { FirebaseKeys, Role, UserPages } from './common/constants';
import useWindowDimensions from './common/hooks/useWindowDimensions';
import "./common/i18n";
import NotificationBanner from './components/NotificationBanner';
import ProtectedRoute from './components/ProtectedRoute';
import LoginDialog from './components/dialogs/LoginDialog';
import NotificationPermissionDialog from './components/dialogs/NotificationPermissionDialog';
import SideNav from './components/layouts/SideNav';
import ThemeProvider from "./components/layouts/ThemeProvider";
import MacroEditor from './components/macros/MacroEditor';
import MacroPreview from './components/macros/MacroPreview';
import app, { auth } from "./firebase";
import ActionHandler from './pages/ActionHandler';
import Admin from './pages/Admin';
import Career from './pages/Career';
import Careers from './pages/Careers';
import ChangePassword from './pages/ChangePassword';
import Course from './pages/Course';
import CourseEditor from "./pages/CourseEditor";
import Courses from './pages/Courses';
import Dashboard from "./pages/Dashboard";
import EditionEditor from './pages/EditionEditor';
import Login from './pages/Login';
import Macros from './pages/Macros';
import Messages from './pages/Messages';
import ModuleEditor from './pages/ModuleEditor';
import Notifications from "./pages/Notifications";
import PageNotFound from './pages/PageNotFound';
import Profile from './pages/Profile';
import Settings from './pages/Settings';
import Student from './pages/Student';
import Teacher from './pages/Teacher';
import Users from './pages/Users';
import "./themes.css";
import { closeWebSocket, initWebSocket } from './websocket';


function App() {
  const { width } = useWindowDimensions()
  const [user, setUser] = useState(null)
  const [dropdown, setDropdown] = useState(null)
  const [loginDialog, setLoginDialog] = useState(null)
  const [sidenav, setSideNav] = useState(true)
  const [notificationPermissions, setNotificationPermissions] = useState(false)
  const [notificationBanner, setNotificationBanner] = useState(null)
  const [notifications, setNotifications] = useState(null)
  const [unreadMessages, _setUnreadMessages] = useState(0)
  const [whiteboard, setWhiteboard] = useState({ show: false, persistenceKey: null, pdf: null })

  const setUnreadMessages = data => {
    unreadMessageRef.current = data
    _setUnreadMessages(data)
  }

  const context = {
    user: user,
    setUser: setUser,
    isLogging: null,
    dropdown: dropdown,
    setDropdown: setDropdown,
    loginDialog: loginDialog,
    setLoginDialog: setLoginDialog,
    sidenav: sidenav,
    setSideNav: setSideNav,
    notificationPermissions: notificationPermissions,
    setNotificationPermissions: setNotificationPermissions,
    notificationBanner: notificationBanner,
    setNotificationBanner: setNotificationBanner,
    notifications: notifications,
    setNotifications: setNotifications,
    unreadMessages: unreadMessages,
    setUnreadMessages: setUnreadMessages,
    whiteboard: whiteboard,
    setWhiteboard: setWhiteboard
  }

  const unreadMessageRef = useRef(context.unreadMessages)

  useEffect(() => {

    //Gestisce le notifiche push ricevute con app aperta.
    isSupported().then(supported => {
      if (supported) {
        let messaging = getMessaging(app)
        if (messaging) {
          onMessage(messaging, (payload) => {
            setNotificationBanner({ title: payload.notification.title, body: payload.notification.body, image: payload.notification.image })
          })
        }
      }
    })

    auth.authStateReady().then(() => {
      auth.onAuthStateChanged((user) => {
        //Se l'utente è loggato su firebase
        if (user) {
          // //Ottiene le info dell'utente dal backend.
          const getUser = async () => {
            try {
              let u = await api.get("/admin/")
              if (u) {
                context.setUser(u)
                console.debug(u)
                context.setLoginDialog(false)
              }
            }
            catch (e) {
              console.error(e)
              await auth.signOut()
              context.setUser(null)
            }
          }
          if (!context.isLogging) {
            getUser()
          }
        }
      });
    })
  }, []);

  /**
   * Quando cambia l'utente nel context
   */
  useEffect(() => {
    if (context.user && auth.currentUser) {
      // const getNotifications = async () => {
      //   try {
      //     let notifications = await api.get("/notifications?page=1&per=4")
      //     context.setNotifications(notifications)
      //   }
      //   catch (e) {
      //     console.error(e)
      //   }
      // }
      isSupported().then((supported) => {
        if (supported) {
          if (Notification.permission === 'default') {
            context.setNotificationPermissions(true)
          }
          if (Notification.permission === 'granted') {
            getNotificationsToken()
          }
        }
      })

      // getNotifications()
      initWebSocket(auth.currentUser.uid, handleReceivedMessage)
    }

    return () => {
      // Chiudi la connessione WebSocket quando il componente viene smontato
      closeWebSocket();
    };
  }, [context.user])

  //Handler per i messaggi del websocket.
  const handleReceivedMessage = (data) => {
    if (data.type === "unread") {
      setUnreadMessages(data.value)
    }
    if (data.type === "received") {
      setUnreadMessages(unreadMessageRef.current + 1)
      if (!window.location.pathname.includes("messages")) {
        context.setNotificationBanner({ title: data.sender_name, body: data.body, url: "/messages", image: data.sender_picture })
      }
    }
    document.dispatchEvent(new CustomEvent('message-received', { detail: data }));
  };

  //Ottiene il token FCM e lo invia al backend.
  const getNotificationsToken = async () => {
    try {
      let supported = await isSupported()
      if (supported) {
        await navigator.serviceWorker.register('/firebase-messaging-sw.js')
        let messaging = getMessaging(app)
        if (messaging) {
          if (Notification.permission === "granted") {
            console.debug("Getting Token from firebase")
            try {
              let token = await getToken(messaging, { vapidKey: FirebaseKeys.Vapid })

              console.debug("Sending token to backend")
              await api.post("/tokens", { token: token })
            }
            catch (e) {
              console.error(e)
            }
          } else {
            console.log("Notifications Permission Denied")
          }
        }
      }
    } catch (e) {
      console.error(e)
    }

  }

  return (
    <Router>
      <MainContext.Provider value={context}>
        <ThemeProvider theme={'starting-finance'}>
          <div className="container">
            <SideNav open={width >= 768}></SideNav>
            <LoginDialog open={context.loginDialog} onClose={() => { context.setLoginDialog(false) }} />
            <NotificationPermissionDialog open={context.notificationPermissions} onClose={() => {
              context.setNotificationPermissions(false)
              getNotificationsToken()
            }} />
            <NotificationBanner notification={context.notificationBanner} />

            <div className='content'>
              <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="*" element={<PageNotFound />} />
                <Route path="/actions" element={<ActionHandler />} />

                <Route path="/" element={<ProtectedRoute component={<><Dashboard /></>} />} />
                <Route path="/careers" element={<ProtectedRoute component={<><Careers /></>} />} />
                <Route path="/careers/:careerId" element={<ProtectedRoute component={<><Career /></>} />} />
                <Route path="/courses" element={<ProtectedRoute component={<><Courses /></>} />} />
                <Route path="/courses/:courseId" element={<ProtectedRoute component={<><Course /></>} />} />
                <Route path="/courses/:courseId/editor" element={<ProtectedRoute component={<><CourseEditor /></>} />} />
                <Route path="/courses/:courseId/editions/:editionId" element={<ProtectedRoute component={<><EditionEditor /></>} />} />
                <Route path="/courses/:courseId/editions/:editionId/modules/:moduleId" element={<ProtectedRoute component={<><ModuleEditor /></>} />} />
                <Route path="/users" element={<ProtectedRoute component={<><Users defaultSelectedTab={UserPages.Students} /></>} />} />
                <Route path="/users/students" element={<ProtectedRoute component={<><Users defaultSelectedTab={UserPages.Students} /></>} />} />
                <Route path="/users/students/:studentId" element={<ProtectedRoute component={<><Student /></>} />} />
                <Route path="/users/teachers" element={<ProtectedRoute component={<><Users defaultSelectedTab={UserPages.Teachers} /></>} />} />
                <Route path="/users/teachers/:teacherId" element={<ProtectedRoute component={<><Teacher /></>} />} />
                <Route path="/users/tutors" element={<ProtectedRoute component={<><Users defaultSelectedTab={UserPages.Tutors} /></>} />} />
                <Route path="/users/tutors/:tutorId" element={<ProtectedRoute component={<><Teacher role={Role.Tutor} /></>} />} />
                <Route path="/users/admins" element={<ProtectedRoute component={<><Users defaultSelectedTab={UserPages.Admins} /></>} />} />
                <Route path="/users/admins/:adminId" element={<ProtectedRoute component={<><Admin /></>} />} />
                <Route path="/notifications" element={<ProtectedRoute component={<><Notifications /></>} />} />
                <Route path="/messages/" element={<ProtectedRoute component={<><Messages /></>} />} />
                <Route path="/macros/" element={<ProtectedRoute component={<><Macros /></>} />} />
                <Route path="/macros/:macroId" element={<ProtectedRoute component={<><MacroEditor /></>} />} />
                <Route path="/macroPreview/:previewId" element={<ProtectedRoute component={<><MacroPreview /></>} />} />
                <Route path="/settings" element={<ProtectedRoute component={<><Settings /></>} />} />
                <Route path="/profile" element={<ProtectedRoute component={<><Profile /></>} />} />
                <Route path="/changePassword" element={<ProtectedRoute component={<><ChangePassword /></>} />} />
              </Routes>
            </div>
          </div>
        </ThemeProvider>
      </MainContext.Provider>
    </Router>
  );
}

export default App;
