import { useCallback, useState } from "react"
import styles from "./Card.module.css"
import { useTranslation } from "react-i18next"

const Card = ({ children, style, onClick = () => { }, error = false, hover = false, title = "", tag = "" }) => {
    const [_hover, setHover] = useState(false)
    const { t } = useTranslation()
    const enter = useCallback(() => {
        if (hover) {
            setHover(true)
        }
    }, [hover])

    const exit = useCallback(() => {
        setHover(false)
    }, [])

    return (
        <div
            onMouseEnter={enter}
            onMouseLeave={exit}
            className={styles.container} style={{
                ...style,
                border: error ? "1px dotted rgba(var(--secondary-rgb), 40%)" :
                    _hover ? "1px solid var(--primary)" :
                        "1px solid rgba(var(--text-color-rgb), 12%)",
            }} onClick={onClick}>
            <div className={styles.content}>
                {
                    title && !tag &&
                    <div className={_hover ? `${styles.title} ${styles.hover}` : styles.title}
                    >{title}</div>
                }
                {
                    title && tag &&
                    <div className={styles.titleWithTag}>
                        <div className={_hover ? `${styles.title} ${styles.hover}` : styles.title}>
                            {title}
                        </div>
                        <div className={styles.tag}>
                            {tag}
                        </div>
                    </div>
                }

                {children}
            </div>
        </div>
    )

}

export default Card
