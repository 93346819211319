import { useEffect, useState } from "react"
import { ReactSortable } from "react-sortablejs"
import { ReactComponent as AddIcon } from "../assets/images/icons/ic-add-circle.svg"
import { ReactComponent as ChevronIcon } from "../assets/images/icons/ic-chevron.svg"
import { Each } from "../common/Each"
import Button from "./Button"
import styles from "./CourseFaqEditor.module.css"
import TextInput from "./TextInput"

const CourseFaqEditor = ({ faq, onChange = () => { } }) => {

    const [courseFaq, setCourseFaq] = useState(faq)

    useEffect(() => {
        onChange(courseFaq)
    }, [courseFaq])


    return (
        <div className={styles.container}>
            <ReactSortable list={courseFaq} setList={setCourseFaq} dragClass={styles.dragClass} dragoverBubble={true} ghostClass={styles.ghostClass} chosenClass={styles.chosenClass} handle=".handle" draggable=".sortable-faq">
                <Each of={courseFaq} render={(item, index) => {
                    return (
                        <div className={`${styles.item} ${item._selected ? styles.open : ''} sortable-faq`}>
                            <div className={`${styles.header} ${item._selected ? styles.open : ''} handle`} onClick={() => {
                                let newCourseFaq = courseFaq.map((itm, idx) => {
                                    if (idx === index) {
                                        itm._selected = !itm._selected
                                    }
                                    return itm
                                })
                                setCourseFaq([...newCourseFaq])
                            }}>
                                <div className={styles.title}>{item.name}</div>
                                <ChevronIcon className={`${styles.chevron} ${item._selected ? styles.open : ''}`} />
                            </div>
                            <div className={`${styles.content} ${item._selected ? styles.open : ''}`} >
                                <TextInput
                                    style={{ fontSize: '.75rem' }}
                                    type="text"
                                    placeholder={"Domanda"}
                                    value={item.name}
                                    onKeyUp={(value) => {
                                        let newCourseFaq = courseFaq.map((itm, idx) => {
                                            if (idx === index) {
                                                itm.name = value
                                            }
                                            return itm
                                        })
                                        setCourseFaq([...newCourseFaq])
                                    }} />
                                <textarea className={styles.description} value={item.description} placeholder="Risposta" onChange={(e) => {
                                    let value = e.target.value
                                    let newCourseFaq = courseFaq.map((itm, idx) => {
                                        if (idx === index) {
                                            itm.description = value
                                        }
                                        return itm
                                    })
                                    setCourseFaq([...newCourseFaq])
                                }} />
                                <Button onClick={() => {
                                    let newCourseFaq = courseFaq.filter((itm, idx) => {
                                        return (idx !== index)
                                    })
                                    setCourseFaq([...newCourseFaq])

                                }} appearance="text" accentColor="var(--secondary)" style={{ width: 'fit-content' }}>Elimina</Button>
                            </div>
                        </div>
                    )
                }} />
            </ReactSortable>
            <Button fullWidth accentColor="var(--tertiary)"
                style={{ borderRadius: '0 0 1rem 1rem' }}
                onClick={() => {
                    let newItem = { icon: '', title: 'Nuovo', description: '' }
                    setCourseFaq((prev) => {
                        return [...prev, newItem]
                    })
                }}>
                <AddIcon className={styles.addIcon} />
                AGGIUNGI
            </Button>
        </div>
    )
}

export default CourseFaqEditor