import { useState } from "react"
import { Each } from "../../common/Each"
import Select from "../Select"
import TextInput from "../TextInput"
import Dialog from "./Dialog"
import styles from "./IconsDialog.module.css"
import { FluentIcons } from "../../common/icons"

const IconsDialog = ({ open = false, selected, onClose = () => { }, onSelect = () => { } }) => {


    const filledIcons = FluentIcons.filter(i => i.style === 'filled').sort((a, b) => { return a.name - b.name })
    const outlinedIcons = FluentIcons.filter(i => i.style === 'outlined')
    const [iconMode, setIconMode] = useState('filled')
    const [filteredIcons, setFilteredIcons] = useState(filledIcons)
    const [searchIconValue, setSearchIconValue] = useState('')

    const handleOnSelect = async (icon) => {
        onSelect(icon)
    }

    const handleOnClose = async () => {
        onClose()
    }

    return (
        <Dialog open={open} title={"Scegli icona"} onClose={handleOnClose}>
            <div className={styles.iconDialog}>
                <div className={styles.iconDialogHeader}>
                    <Select selected={{ id: 0, name: 'Filled', value: 'filled' }} options={[{ id: 0, name: 'Filled', value: 'filled' }, { id: 1, name: 'Outlined', value: 'outlined' }]}
                        onSelect={(selected) => {
                            setIconMode(selected.value)
                            let result = (selected.value === 'filled' ? filledIcons : outlinedIcons)
                            if (searchIconValue.length > 0) {
                                result = result.filter(i => {
                                    return i.svgFileName.toLowerCase().includes(searchIconValue.trim().toLowerCase().replace(' ', ''))
                                })
                            }
                            setFilteredIcons(result)
                        }} />
                    <TextInput value={searchIconValue} type="search" placeholder="Cerca icona" onKeyUp={(value) => {
                        setSearchIconValue(value)
                        let iconPack = (iconMode === 'filled' ? filledIcons : outlinedIcons)
                        if (value && value.length > 0) {
                            let results = iconPack.filter(i => {
                                return i.svgFileName.toLowerCase().includes(value.trim().toLowerCase().replace(' ', ''))
                            })
                            setFilteredIcons(results)
                        }
                        else {
                            setFilteredIcons(iconMode === 'filled' ? filledIcons : outlinedIcons)
                        }
                    }} />
                </div>
                <div className={styles.selectIcons}>
                    <Each of={filteredIcons} render={(icon) => {
                        return (
                            <div className={`${styles.selectIconWrapper} ${selected === icon.svgFileName ? styles.selected : ''}`} onClick={() => { handleOnSelect(icon) }}>
                                <div className={styles.selectIcon}
                                    dangerouslySetInnerHTML={{ __html: icon.data.replace(/fill="#[^"]*"/g, 'fill="currentColor"').replace(/stroke="#[^"]*"/g, 'stroke="currentColor"') }}
                                />
                            </div>
                        )
                    }} />
                </div>
            </div>
        </Dialog>
    )

}

export default IconsDialog