import { useTranslation } from "react-i18next"
import NotificationsDropdown from "../NotificationsDropdown"
import TextInput from "../TextInput"
import style from "./Header.module.css"

const Header = () => {

    const { t } = useTranslation()


    return (
        <div className={style.container}>
            <div className={style.search}>
                <TextInput placeholder={t('header.search')} type="search"></TextInput>
            </div>

            <div className={style.dropdowns}>
                <NotificationsDropdown />
            </div>
        </div >
    )

}

export default Header
