import { useCallback, useContext, useEffect, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ReactComponent as CalendarIcon } from "../assets/images/icons/ic-calendar.svg";
import { ReactComponent as EmailIcon } from "../assets/images/icons/ic-email.svg";
import { ReactComponent as EditIcon } from "../assets/images/icons/ic-image-edit.svg";
import { ReactComponent as LogoutIcon } from "../assets/images/icons/ic-logout.svg";
import { ReactComponent as CartIcon } from "../assets/images/icons/ic-cart.svg"
import { ReactComponent as CopIcon } from "../assets/images/icons/ic-cop.svg"
import { ReactComponent as BagIcon } from "../assets/images/icons/ic-bag.svg"
import UserPlaceholder from "../assets/images/placeholders/user-placeholder.png";
import MainContext from "../common/MainContext";
import Button from "../components/Button";
import HeaderFooterLayout from "../components/layouts/HeaderFooterLayout";
import Skeleton from "../components/Skeleton";
import { auth } from "../firebase";
import styles from "./Profile.module.css";
import EditProfile from "../components/EditProfile";
import { AdminRole } from "../common/constants";

const Profile = ({ }) => {
  const { t } = useTranslation();
  const navigate = useNavigate()
  const context = useContext(MainContext)
  const [showEditPage, setShowEditPage] = useState(false)

  useEffect(() => {
    window.scrollTo({
      top: 0,
    })
  }, [])

  useEffect(() => {

    console.debug(context)
  }, [context])

  const logout = useCallback(async () => {
    await auth.signOut()
    context.setUser(null)
    navigate("/")
  }, [])

  return (
    <HeaderFooterLayout>
      <HelmetProvider>
        <Helmet>
          <title>
            Profilo
          </title>
        </Helmet>
      </HelmetProvider>
      <div className={styles.container}>
        <div className={styles.section}>
          {
            showEditPage && <EditProfile onBack={() => setShowEditPage(false)} />
          }

          {
            !showEditPage &&
            <div className={styles.sectionInner}>
              <div className={styles.profileContainer}>
                <div className={styles.profile}>
                  <div className={styles.admin}>
                    {
                      context.user?.picture &&
                      <img className={styles.profilePic} src={context.user?.picture} alt='' />
                    }
                    {
                      !context.user?.picture &&
                      <img className={styles.profilePic} src={UserPlaceholder} alt='' />
                    }
                    {
                      !context.user &&
                      <Skeleton type="rect" width="164px" height="24px" borderRadius={"12px"} />
                    }
                    {
                      context.user &&
                      <div className={styles.username}>{`${context.user?.name} ${context.user?.surname}`}</div>
                    }
                    <Button
                      disabled={!context.user}
                      onClick={() => {
                        setShowEditPage(true)
                      }}
                    >
                      <EditIcon />
                      {t("profile.edit").toUpperCase()}
                    </Button>
                  </div>
                  <div className={styles.adminInfo}>
                    <div className={styles.attribute}>
                      <div className={styles.label}>
                        <EmailIcon className={styles.profileIcon} />
                        {t("profile.email")}
                      </div>
                      <div className={styles.attributeValue}>{context.user?.email}</div>
                    </div>
                    <div className={styles.attribute}>
                      <div className={styles.label}>
                        <CalendarIcon className={styles.profileIcon} />
                        data creazione
                      </div>
                      <div className={styles.attributeValue}>{new Date(context.user?.created_at).toLocaleString("it-IT")}</div>
                    </div>
                    <div className={styles.attribute}>
                      <div className={styles.label}>
                        <BagIcon className={styles.profileIcon} />
                        ruolo
                      </div>
                      {
                        context.user?.role === AdminRole.Main &&
                        <div className={styles.attributeValue}> Main <CopIcon /></div>
                      }
                      {
                        context.user?.role === AdminRole.Sales &&
                        <div className={styles.attributeValue}> Sales <CartIcon /></div>
                      }
                    </div>
                  </div>
                  <div
                    className={styles.logout}
                    onClick={logout}
                  >
                    <LogoutIcon />
                    Logout
                  </div>
                </div>
              </div>

            </div>
          }
        </div>
      </div>

    </HeaderFooterLayout>

  )
}


export default Profile
