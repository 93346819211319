import { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { useNavigate } from "react-router-dom";
import typo from "../../typography.module.css";
import styles from "./CareerPreview.module.css";
import CareerProgress from "../CareerProgress"
import { ReactComponent as SalaryIcon } from "../../assets/images/icons/ic-salary.svg"
import { ReactComponent as InfoIcon } from "../../assets/images/icons/ic-info.svg"
import { ReactComponent as BookIcon } from "../../assets/images/icons/ic-book-inactive.svg";
import { ReactComponent as CartIcon } from "../../assets/images/icons/ic-cart.svg";
import { ReactComponent as ClockIcon } from "../../assets/images/icons/ic-clock.svg";
import { Each } from "../../common/Each";
import Tag from "../Tag"
import CourseBadge from "../CourseBadge";
import CourseCard from "../cards/CourseCard"
import { ArticleCardType, TagType } from "../../common/constants";
import { useTranslation } from "react-i18next";
import { formatPrice } from "../../utils";
import Button from "../Button";
import useWindowDimensions from "../../common/hooks/useWindowDimensions";
import ArticleCard from "../cards/ArticleCard";
import { AnimatePresence, motion } from "framer-motion";

const Career = ({ career, course, partners, relatedCourses, relatedArticles, }) => {

    const { t } = useTranslation()
    const { width } = useWindowDimensions()
    const [discount, setDiscount] = useState(null)

    useEffect(() => {
        if (course) {
            if (course.product) {
                let discount = (course.product.price - course.product.discounted_price) / course.product.price * 100
                setDiscount(Math.round(discount))
            }
        }
    }, [course])

    return (
        <div className={styles.container}>
            {career &&
                <>
                    <div className={styles.section} style={{ backgroundColor: 'var(--background-secondary-color)' }}>
                        <div className={styles.sectionInner}>
                            <div className={styles.careerLeft}>
                                <div className={styles.playerWrapper}>
                                    <ReactPlayer
                                        url={career.video_url}
                                        controls
                                        width="100%"
                                        height="100%"
                                        className={styles.player}
                                    />
                                </div>
                            </div>
                            <div className={styles.careerRight}>
                                <div className={typo.subtitle}>{career.name}</div>
                                <div className={styles.mobilePlayerWrapper}>
                                    <ReactPlayer
                                        url={career.video_url}
                                        controls
                                        width="100%"
                                        height="100%"
                                        className={styles.player}
                                    />
                                </div>
                                {/* <CareerProgress name={t('career.yourProgress')}
                                        style={{ maxWidth: width > 540 ? '360px' : 'none' }}
                                    /> */}
                                {career.salary_avg &&
                                    <div className={styles.salary}>
                                        <SalaryIcon></SalaryIcon>
                                        <div className={styles.salaryLabel}>SALARIO MEDIO</div>
                                        <div className={styles.salaryValue}>{career.salary_avg}</div>
                                        <div className={styles.spacer} />
                                        <InfoIcon></InfoIcon>
                                    </div>
                                }
                                <div className={typo.body}>
                                    {career.description}
                                </div>
                                <div className={styles.tags}>
                                    <Each of={career.tags} render={(tag) => {
                                        return <Tag tag={tag} />
                                    }} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <AnimatePresence>
                        {course &&
                            <motion.div
                                initial={{ opacity: 0, height: 0 }}
                                animate={{ opacity: 1, height: 'auto' }}
                                className={`${styles.section} ${styles.bordered}`}>
                                <div className={styles.sectionInner}>
                                    <div className={styles.courseLeft}>
                                        <CourseBadge type={course.type}></CourseBadge>
                                        <div className={typo.subtitle}>{course.name}</div>
                                        <img className={styles.thumbnailMobile} src={course.thumbnail} alt="course-thumbnail-mobile" />

                                        <div className={typo.body}>{course.description}</div>
                                        <div className={styles.featureRow}>
                                            <div className={styles.feature}>
                                                <BookIcon></BookIcon> {course.program.length} moduli
                                                <ClockIcon></ClockIcon> {course.duration} ore
                                            </div>
                                            <Each of={course.tags} render={(tag) => {
                                                if (tag.type === TagType.Feature) {
                                                    return (
                                                        <div className={styles.feature}>
                                                            <img src={tag.icon} alt={tag.name} /> {t(`tags.${tag.name}`)}
                                                        </div>
                                                    )
                                                }
                                            }} />
                                        </div>
                                        <div className={styles.priceRow}>
                                            {discount &&
                                                <>
                                                    <div className={typo.subtitle} style={{ fontSize: '1.25rem' }}>{formatPrice(course.product.discounted_price)}€</div>
                                                    <div className={styles.discount}><div>{formatPrice(course.product.price)}€</div> {t('professional.discountOf')} {discount}%</div>
                                                </>
                                            }
                                            {!discount &&
                                                <div className={typo.subtitle} style={{ fontSize: '1.25rem' }}>{formatPrice(course.product.price)}€</div>
                                            }
                                        </div>
                                        <div className={styles.actionRow}>
                                            <div>
                                                <Button style={{ fontSize: '1.15rem', width: '100%', padding: '.5rem' }}>
                                                    <CartIcon></CartIcon>{t('courses.purchase').toUpperCase()}
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.courseRight}>
                                        <img className={styles.thumbnail} src={course.thumbnail} alt="course-thumbnail" />
                                    </div>
                                </div>
                            </motion.div>
                        }
                    </AnimatePresence>

                    {partners && partners.length > 0 &&
                        <div className={styles.section} style={{ backgroundColor: 'var(--background-secondary-color)', paddingBottom: 0 }}>
                            <div id={styles['sectionInner']} className={styles.sectionInner} style={{ flexDirection: 'column' }}>
                                <div className={`${typo.title} ${styles.sectionTitle}`} style={{ fontSize: '1.15rem' }}>
                                    {t('career.partners')}
                                </div>
                                <div className={styles.partners}>
                                    <div className={styles.partnersTrack}>
                                        <Each of={partners} render={(partner) => {
                                            return (
                                                <div className={styles.partnerCard}>
                                                    <img className={styles.partnerLogo} alt={`${partner}-logo`} src={partner.logo} />
                                                </div>
                                            )
                                        }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    {relatedCourses && relatedCourses.length > 0 &&
                        <div className={styles.section} style={{ backgroundColor: 'var(--background-secondary-color)', paddingBottom: 0 }}>
                            <div id={styles['sectionInner']} className={styles.sectionInner} style={{ flexDirection: 'column' }}>
                                <div className={`${typo.title} ${styles.sectionTitle}`} style={{ fontSize: '1.15rem' }}>
                                    {t('career.relatedCourses')}
                                </div>
                                <div className={styles.courses}>
                                    <div className={styles.coursesTrack}>
                                        <Each of={relatedCourses} render={(c) => {

                                            return (
                                                <div className={styles.courseCard}>
                                                    <CourseCard course={c} />
                                                </div>
                                            )
                                        }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    {relatedArticles && relatedArticles.length > 0 &&
                        <div className={styles.section} style={{ backgroundColor: 'var(--background-secondary-color)' }}>
                            <div className={styles.sectionInner}>
                                <div className={typo.title} style={{ fontSize: '1.15rem' }}>
                                    {t('career.relatedArticles')}
                                </div>
                                <div className={styles.articles}>
                                    <div className={styles.bigArticle}>
                                        <ArticleCard article={relatedArticles[0]} type={width > 1180 ? ArticleCardType.Large : ArticleCardType.Small} />
                                    </div>
                                    <div className={styles.smallArticles}>
                                        <Each of={relatedArticles.slice(1, 4)} render={(article) => {
                                            return (
                                                <div className={styles.articleCard}>
                                                    <ArticleCard article={article} type={ArticleCardType.Small} />
                                                </div>
                                            )
                                        }} />
                                    </div>
                                </div>
                                <div className={styles.articles} style={{ paddingTop: 0 }}>
                                    <Each of={relatedArticles.slice(4, relatedArticles.length + 1)} render={(article) => {
                                        return <ArticleCard article={article} type={width > 1180 ? ArticleCardType.Medium : ArticleCardType.Small} />
                                    }} />
                                </div>
                            </div>
                        </div>
                    }
                </>
            }
        </div>
    )
}

export default Career