import { useEffect, useState } from "react"
import { ReactComponent as ChevronIcon } from "../assets/images/icons/ic-chevron.svg"
import { Each } from "../common/Each"
import styles from "./SimpleCollapsable.module.css"

const SimpleCollapsable = ({ children = <></>, expanded = false, expadendHeight, style = {} }) => {

    const [exp, setExp] = useState(expanded)

    useEffect(() => {
        setExp(expanded ?? false)
    }, [expanded])

    return (
        <div
            style={{
                ...style,
                height: exp ? expadendHeight : 0
            }}
            className={styles.container}
        >
            {children}
        </div>
    )
}

export default SimpleCollapsable
