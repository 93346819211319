import { Each } from "../common/Each"
import styles from "./AutoplayCarousel.module.css"

const AutoplayCarousel = ({ elements, style }) => {

    return (
        <div className={styles.container} style={style}>
            <div className={styles.gradientLeft}></div>
            <div className={`${styles.track} ${elements.length > 3 ? styles.animated : ''}`}>
                <Each of={elements} render={(e, index) => {
                    return (
                        <div className={`${styles.card} ${elements.length > 3 ? styles.animated : ''}`}>
                            <img src={e} alt=""></img>
                        </div>
                    )
                }} />
                {elements.length > 3 &&
                <>
                    <Each of={elements} render={(e, index) => {
                        return (
                            <div className={`${styles.card} ${elements.length > 3 ? styles.animated : ''}`}>
                                <img src={e} alt=""></img>
                            </div>
                        )
                    }} />
                    <Each of={elements} render={(e, index) => {
                        return (
                            <div className={`${styles.card} ${elements.length > 3 ? styles.animated : ''}`}>
                                <img src={e} alt=""></img>
                            </div>
                        )
                    }} />
                </>
                }
            </div>
            <div className={styles.gradientRight}></div>
        </div>
    )

}

export default AutoplayCarousel