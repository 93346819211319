import styles from "./TeacherPreviewCard.module.css"
import Card from "../cards/Card"
import typo from "../../typography.module.css"
import { Each } from "../../common/Each"
import Tag from "../Tag"
import { ReactComponent as CloseIcon } from "../../assets/images/icons/ic-close.svg"

const TeacherPreviewCard = ({teacher, expanded, onClose, onClick = () => {}}) => {

    const handleOnClick = () => {
        if(onClose && typeof onClick === 'function'){
            onClose()
        }
    }

    return(
        <Card style={{padding: '2rem 1.25rem', width: '100%'}}>
            {expanded &&
                <button aria-label="close-card" className={styles.closeCard} onClick={handleOnClick}>
                    <CloseIcon style={{width: '14px', height: '14px', minWidth: '14px', minHeight: '14px'}}/>
                </button>
                }
            <div className={`${styles.container} ${expanded ? styles.expanded : ''}`} onClick={onClick}>
            
                <div className={styles.info}>
                    <img src={teacher.picture} className={styles.avatar} style={{borderColor: teacher.color}} alt="teacher"></img>
                    <div className={styles.infoInner}>
                        <div className={`${styles.name} ${expanded ? styles.expanded : ''}`}>{teacher.name} {teacher.surname}</div>
                        <div className={`${styles.qualification} ${expanded ? styles.expanded : ''}`}>{teacher.qualification}</div>
                    </div>
                </div>
                <div className={styles.tags}>
                    <Each of={teacher.tags} render={(tag, index) => {
                        return(<Tag tag={tag}/>)
                    }}/>
                </div>
                <div className={`${styles.description} ${typo.body} ${expanded ? styles.expanded : ''}`}>
                    {teacher.description}
                </div>
            </div>
        </Card>
    )
}

export default TeacherPreviewCard