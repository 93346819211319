import { formatDateV2, formatTimeV2 } from "../../utils"
import styles from "./ModuleLessonCard.module.css"
import { ReactComponent as CalendarIcon } from "../../assets/images/icons/ic-calendar.svg"
import { ReactComponent as EditIcon } from "../../assets/images/icons/ic-edit.svg"
import { Each } from "../../common/Each"
import Tag from "../Tag"
import { useTranslation } from "react-i18next"
import Button from "../Button"

const ModuleLessonCard = ({ lesson, onEdit = () => { } }) => {
    const { t } = useTranslation()

    return (
        <div className={styles.container}>
            <div className={styles.left}>
                <div className={styles.name}>{lesson.name}</div>
                <div className={styles.time}><CalendarIcon /> {formatTimeV2(lesson.starts_at)} - {formatTimeV2(lesson.ends_at)}, {formatDateV2(lesson.starts_at)}</div>
                {lesson.description &&
                    <div className={styles.description}>{lesson.description}</div>
                }
                <div className={styles.tags}>
                    <Each
                        of={lesson.tags ?? []}
                        render={
                            (tag) => (
                                <Tag tag={tag} />
                            )
                        }
                    />
                </div>
                <Button inverse onClick={() => {
                    onEdit(lesson)
                }}>
                    <EditIcon /> {t(`lessons.edit`).toUpperCase()}
                </Button>
            </div>
            <div className={styles.right}>
                <img className={styles.thumbnail} src={lesson.thumbnail} alt="" />
            </div>


        </div>
    )
}

export default ModuleLessonCard
