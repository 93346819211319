import { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import api from "../api"
import { Each } from "../common/Each"
import StudentCardItem from "./cards/StudentCardItem"
import Pagination from "./Pagination"
import TextInput from "./TextInput"
import styles from "./Students.module.css"

const Students = () => {
    const { t } = useTranslation()

    const [loadingStudents, setLoadingStudents] = useState(false)
    const [loadingPage, setLoadingPage] = useState(true)
    const [students, setStudents] = useState([])
    const [count, setCount] = useState(null)
    const [page, setPage] = useState(0)
    const [per, setPer] = useState(10)
    const [showPagination, setShowPagination] = useState(true)

    useEffect(() => {
        getStudents()
    }, [page])

    const getStudents = useCallback(async (q = null) => {

        setLoadingStudents(true)
        try {
            let route = q ?
                `/admin/users?q=${q}` :
                `/admin/users?page=${page}&per=${per}`

            const students = await api.get(route)
            const { users, count } = students
            setStudents(users)
            setCount(count)
            console.debug(students)
        } catch (e) {
            console.error(e)
        }
        setLoadingStudents(false)
        if (loadingPage) {
            setLoadingPage(false)
        }
    }, [page])

    const onSearch = useCallback(async (text) => {
        console.debug(text)
        if (text && text.length >= 3) {
            setShowPagination(false)
            getStudents(text)
            return;
        }

        if (!text) {
            console.debug("resetting")
            setShowPagination(true)
            setPage(0)
            getStudents()
        }
    }, [getStudents])

    return (
        <div className={styles.container}>
            <div className={styles.section}>
                <div className={styles.sectionInner}>
                    <TextInput
                        onKeyUp={onSearch}
                        type="search"
                        placeholder={t("search")}
                        style={{
                            backgroundColor: "var(--background-secondary-color)",

                        }} />

                    <div className={styles.students}>
                        {
                            loadingStudents &&
                            <Each
                                of={Array(per).fill("")}
                                render={() => (
                                    <StudentCardItem student={null} loading />
                                )}
                            />
                        }
                        {
                            !loadingStudents &&
                            <Each
                                of={students}
                                render={(student) => (
                                    <StudentCardItem student={student} />
                                )}
                            />
                        }

                        {
                            !loadingStudents && students.length === 0 &&
                            <div className={styles.noData}>Non sono presenti studenti</div>
                        }
                    </div>
                    {
                        showPagination &&
                        <Pagination total={count} per={per} page={page} onChange={p => setPage(p)} loading={loadingPage} />
                    }
                </div>
            </div>
        </div>
    )

}

export default Students
