import styles from "./DropdownMultiSelection.module.css"
import { ReactComponent as ChevronIcon } from "../assets/images/icons/ic-chevron.svg"
import { useEffect, useMemo, useRef, useState } from "react"
import { Each } from "../common/Each"
import useClickOutside from "../common/hooks/useClickOutside"

const DropdownMultiSelection = ({ options = [], placeholder = "", selectedOptions = [], appereance = "default", itemsRender = () => {
    return <></>
} }) => {
    const [items, setItems] = useState(selectedOptions)
    const [open, setOpen] = useState(false)
    const [height, setHeight] = useState(null)
    const ref = useRef(null)

    useClickOutside(ref, () => setOpen(false))

    useEffect(() => {
        setItems(selectedOptions)
    }, [selectedOptions])

    useEffect(() => {
        if (options.length === 0) {
            setOpen(false)
        }
    }, [options])

    useEffect(() => {
        if (!ref.current) return;
        const resizeObserver = new ResizeObserver(() => {
            if (ref?.current) {
                setHeight(ref.current.clientHeight)
            }
        });
        resizeObserver.observe(ref.current);
        return () => resizeObserver.disconnect(); // clean up 
    }, []);

    const className = useMemo(() => {
        switch (appereance) {
            case "default": return {
                container: styles.container,
                optionsContainer: styles.options,
                option: styles.option,
            }
            case "embedded": return {
                container: styles.embedded,
                optionsContainer: styles.inverseOptions,
                option: styles.inverseOption,
            }
            default: break;
        }
    }, [])

    return (
        <div ref={ref} className={className.container} onClick={() => setOpen((open) => !open)} style={{
            "--container-height": `${appereance === "embedded" ? height + 8 : height}px`
        }}>
            {
                items.length === 0 && <div className={styles.placeholder}>{placeholder}</div>
            }
            {
                items.length > 0 &&
                <div className={styles.selectedOptions}>
                    <Each
                        of={items}
                        render={itemsRender}
                    />
                </div>

            }
            <ChevronIcon className={`${styles.arrow} ${open ? styles.up : styles.down}`} />
            <div className={`${className.optionsContainer} ${open ? styles.open : styles.hidden}`} onClick={(e) => e.stopPropagation()}>
                <Each
                    of={options}
                    render={itemsRender}
                />
            </div>
        </div>
    )
}

export default DropdownMultiSelection
