import styles from "./RatingBadge.module.css"
import { ReactComponent as RatingStarIcon } from "../assets/images/icons/ic-trustpilot-star.svg"
import { Each } from "../common/Each"

const RatingBadge = ({rating}) => {

    return (
        <div className={styles.container}>
            <Each of={[1,2,3,4,5]} render={(item) => {
                return (
                    <div className={styles.star} 
                    style={{background: rating >= item ? '#00DA8C' : `linear-gradient(90deg, #00DA8C 0%, #00DA8C ${(100-(item-rating)*100)}%, var(--background-secondary-color) ${((100-(item-rating)*100))+1}%` }}
                    > 
                        <RatingStarIcon className={styles.startIcon} />
                    </div>
                )
            }}/>
        </div>
    )

}

export default RatingBadge