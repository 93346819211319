import styles from "./Spinner.module.css"

const Spinner = ({color='var(--background-color)'}) => {

    return (
        <div className={[styles.spinner, styles.center].join(' ')}>
            <div className={styles.spinnerBlade} style={{backgroundColor: color}}></div>
            <div className={styles.spinnerBlade} style={{backgroundColor: color}}></div>
            <div className={styles.spinnerBlade} style={{backgroundColor: color}}></div>
            <div className={styles.spinnerBlade} style={{backgroundColor: color}}></div>
            <div className={styles.spinnerBlade} style={{backgroundColor: color}}></div>
            <div className={styles.spinnerBlade} style={{backgroundColor: color}}></div>
            <div className={styles.spinnerBlade} style={{backgroundColor: color}}></div>
            <div className={styles.spinnerBlade} style={{backgroundColor: color}}></div>
            <div className={styles.spinnerBlade} style={{backgroundColor: color}}></div>
            <div className={styles.spinnerBlade} style={{backgroundColor: color}}></div>
            <div className={styles.spinnerBlade} style={{backgroundColor: color}}></div>
            <div className={styles.spinnerBlade} style={{backgroundColor: color}}></div>
        </div>
    )
}

export default Spinner