import styles from "./Back.module.css"
import { ReactComponent as ArrowIcon } from "../assets/images/icons/ic-arrow.svg";
import { useTranslation } from "react-i18next";

const Back = ({ onClick = () => { } }) => {
  const { t } = useTranslation()

  return (
    <div
      className={styles.back}
      onClick={onClick}>
      <ArrowIcon /> {t("login.goBack")}
    </div>
  )
}

export default Back
