import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import api from "../api"
import { ReactComponent as AddIcon } from "../assets/images/icons/ic-add-circle.svg"
import { ReactComponent as ChevronIcon } from "../assets/images/icons/ic-chevron.svg"
import { CourseType } from "../common/constants"
import { Each } from "../common/Each"
import Button from "../components/Button"
import Card from "../components/cards/Card"
import CourseBadge from "../components/CourseBadge"
import HeaderFooterLayout from "../components/layouts/HeaderFooterLayout"
import MultiStateSwitch from "../components/MultiStateSwitch"
import Skeleton from "../components/Skeleton"
import typo from "../typography.module.css"
import styles from "./Courses.module.css"

const Courses = () => {

  const navigate = useNavigate()
  const { t } = useTranslation()
  const [courses, setCourses] = useState(null)
  const [filterCourses, setFilterCourses] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const getCourses = async () => {
      setLoading(true)
      try {
        let courses = await api.get("/admin/courses")
        setCourses(courses.sort((a, b) => { return a.name > b.name }))
        setFilterCourses(courses.sort((a, b) => { return a.name > b.name }))
      }
      catch (e) {
        console.error(e)
      }
      setLoading(false)
    }

    if (!courses) {
      getCourses()
    }
  }, [])

  const switchStateChange = async (index) => {
    switch (index) {
      case 0:
        setFilterCourses(courses.sort((a, b) => { return a.name > b.name }))
        break
      case 1:
        setFilterCourses(courses.filter(c => c.type === CourseType.Masterclass).sort((a, b) => { return a.name > b.name }))
        break;
      case 2:
        setFilterCourses(courses.filter(c => c.type === CourseType.Professional).sort((a, b) => { return a.name > b.name }))
        break;
      default:
        setFilterCourses(courses.sort((a, b) => { return a.name > b.name }))
    }
  }

  const getStatusStyle = (status) => {
    switch (status) {
      case 'public':
        return styles.public
      case 'draft':
        return styles.draft
      case 'hidden':
        return styles.hidden
      default:
        return null
    }
  }

  return (
    <HeaderFooterLayout>
      <div className={styles.container}>
        <div className={styles.section}>
          <div className={styles.sectionInner}>
            <div className={typo.title} style={{ display: 'flex', justifyContent: 'space-between' }}>
              {t('courses.title')}
              <Button style={{ padding: '0 1rem' }} onClick={() => { navigate('/courses/create/editor') }}>
                AGGIUNGI
                <AddIcon />
              </Button></div>

            <Card>
              <div className={styles.cardInner}>
                <MultiStateSwitch states={['TUTTI', 'MASTERCLASS', 'PROFESSIONALIZZANTI']} onStateChange={switchStateChange} />
                <div className={styles.courses}>
                  {loading &&
                    <Each of={Array(7).fill('')} render={(i) => {
                      return (
                        <div className={styles.skeletonCourse}>
                          <Skeleton type="rect" width={50} height={50} borderRadius="12px" />
                          <div className={styles.skeletonInfo}>
                            <Skeleton type="rect" width={150} height={20} borderRadius="4px" />
                            <Skeleton type="rect" width={300} height={15} borderRadius="4px" />
                          </div>
                          <Skeleton type="rect" width={60} height={30} borderRadius="8px" />
                          <ChevronIcon className={styles.chevron} />
                        </div>
                      )
                    }} />
                  }
                  {!loading &&
                    <Each of={filterCourses} render={(course) => {
                      return (
                        <div className={styles.course} onClick={() => {
                          navigate(`/courses/${course.id}`)
                        }}>
                          <img src={course.thumbnail} className={styles.thumbnail} alt="" />
                          <div className={styles.info}>
                            <CourseBadge type={course.type} />
                            <div className={styles.title}>{course.name}</div>
                            <div className={styles.description}>{course.description}</div>
                          </div>
                          <div className={`${styles.status} ${getStatusStyle(course.status)}`}>{course.status}</div>
                          <ChevronIcon className={styles.chevron} />
                        </div>
                      )
                    }} />
                  }
                </div>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </HeaderFooterLayout>
  )

}

export default Courses