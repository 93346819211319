import { useEffect, useMemo, useRef, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ReactComponent as AdminsIcon } from "../assets/images/icons/ic-admins.svg";
import { ReactComponent as StudentsIcon } from "../assets/images/icons/ic-students-active.svg";
import { ReactComponent as TeachersIcon } from "../assets/images/icons/ic-teachers-active.svg";
import { ReactComponent as TutorsIcon } from "../assets/images/icons/ic-tutor-active.svg";
import { ReactComponent as AddIcon } from "../assets/images/icons/ic-add-circle.svg";
import { Role, UserPages } from "../common/constants";
import { Each } from "../common/Each";
import useWindowDimensions from "../common/hooks/useWindowDimensions";
import HeaderFooterLayout from "../components/layouts/HeaderFooterLayout";
import TabItem from "../components/TabItem";
import { capitalize } from './../utils';
import Students from "../components/Students";
import Teachers from "../components/Teachers";
import styles from "./Users.module.css";
import typo from "../typography.module.css";
import Button from "../components/Button";
import Admins from "../components/Admins";

const Users = ({ defaultSelectedTab = UserPages.Students }) => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const navigate = useNavigate();

  const [selectedTab, setSelectedTab] = useState(defaultSelectedTab)
  const tabs = useMemo(() => {
    return [
      { id: UserPages.Students, icon: StudentsIcon, text: "Studenti", color: "var(--primary-rgb)", route: `/users/students`, value: null },
      { id: UserPages.Teachers, icon: TeachersIcon, text: "Docenti", color: "var(--tertiary-rgb)", route: `/users/teachers`, value: null },
      { id: UserPages.Tutors, icon: TutorsIcon, text: "Tutor", color: "var(--sf-purple-rgb)", route: `/users/tutors`, value: null },
      { id: UserPages.Admins, icon: AdminsIcon, text: "Admin", color: "var(--secondary-rgb)", route: `/users/admins`, value: null },
    ]
  }, [])

  const teachersRef = useRef(null)
  const tutorsRef = useRef(null)
  const adminsRef = useRef(null)

  useEffect(() => {
    setSelectedTab(defaultSelectedTab)
  }, [defaultSelectedTab])


  const handleAddClick = () => {
    switch (selectedTab) {
      case UserPages.Students: break;
      case UserPages.Teachers: teachersRef.current?.onAddClick(); break;
      case UserPages.Tutors: tutorsRef.current?.onAddClick(); break;
      case UserPages.Admins: adminsRef.current?.onAddClick(); break;
      default: break;
    }
  }

  return (
    <HeaderFooterLayout>
      <HelmetProvider>
        <Helmet>
          <title>
            {capitalize(tabs.find(t => t.id === selectedTab).text)}
          </title>
        </Helmet>
      </HelmetProvider>
      <div className={styles.container}>
        <div className={styles.section}>
          <div className={styles.sectionInner}>
            <div className={typo.title} style={{ display: 'flex', justifyContent: 'space-between' }}>
              Utenti
              {
                selectedTab !== UserPages.Students &&
                <Button style={{ padding: '0.7rem 1rem' }} onClick={handleAddClick}>
                  AGGIUNGI
                  <AddIcon />
                </Button>
              }
            </div>
            <div className={styles.navBarContainer}>
              <Each
                of={tabs}
                render={(item) => (
                  <TabItem
                    selected={selectedTab === item.id}
                    text={capitalize(item.text)}
                    value={item.value}
                    IconComponent={item.icon}
                    color={item.color}
                    onClick={() => {
                      setSelectedTab(item.id)
                      navigate(item.route, { replace: true })
                    }}
                  />
                )
                }
              />
            </div>
            {selectedTab === UserPages.Students && <Students />}
            {selectedTab === UserPages.Teachers && <Teachers ref={teachersRef} role={Role.Teacher} />}
            {selectedTab === UserPages.Tutors && <Teachers ref={tutorsRef} role={Role.Tutor} />}
            {selectedTab === UserPages.Admins && <Admins ref={adminsRef} />}
          </div>
        </div>
      </div>
    </HeaderFooterLayout>
  );
};

export default Users;
