import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import enLocale from "./locales/en.json";
import itLocale from "./locales/it.json";

if(!i18next.isInitialized){
    i18next
        .use(initReactI18next)
        .init({
            resources: {
                en: {
                    translation : enLocale
                },
                it: {
                    translation: itLocale
                } 
            },
            debug: false,
            lng: 'it',
            fallbackLng: "it"
        })
}

export default i18next;