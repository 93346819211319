import { AnimatePresence, motion } from "framer-motion"
import { useContext, useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import { ReactComponent as ClockIcon } from "../assets/images/icons/ic-clock.svg"
import { ReactComponent as NotificationIcon } from "../assets/images/icons/ic-notification.svg"
import { ReactComponent as NotificationFillIcon } from "../assets/images/icons/ic-notification-active.svg"
import { Each } from "../common/Each"
import MainContext from "../common/MainContext"
import useClickOutside from "../common/hooks/useClickOutside"
import typo from "../typography.module.css"
import { calcLastUpdate } from "../utils"
import Button from "./Button"
import styles from "./NotificationsDropdown.module.css"
import api from "../api"

const NotificationsDropdown = () => {

    const [position, setPosition] = useState({ bottom: 0 })
    const dropdownRef = useRef(null)
    const context = useContext(MainContext)
    const navigate = useNavigate()

    const handleOpen = async () => {
        if (context.dropdown === 'notifications') {
            context.setDropdown(null)
        }
        else {
            context.setDropdown('notifications')
            await readNotifications()
        }
    }

    const handleClickOutside = (event) => {
        if (context.dropdown === 'notifications' && event.target.id !== 'notifications-button') {
            context.setDropdown(null)
        }
    }

    useClickOutside(dropdownRef, handleClickOutside)

    const handleSetPosition = () => {
        if (dropdownRef.current) {
            setPosition({ bottom: dropdownRef.current.clientHeight + 4 })
        }
    }

    useEffect(() => {

        handleSetPosition()
        window.addEventListener('resize', handleSetPosition)

        return () => {
            window.removeEventListener('resize', handleSetPosition)
        }
    }, [])

    useEffect(() => {
        handleSetPosition()
    }, [context.notifications])

    const handleNotificationClick = (notification) => {
        if (notification.url) {
            context.setDropdown(null)
            if (notification.url.includes('.')) {
                window.location.href = notification.url
            }
            else {
                navigate(notification.url)
            }
        }
    }

    const readNotifications = async () => {
        try {
            let ids = context.notifications?.page.filter(n => !n.read_at).map(n => n.id)
            if (ids.length > 0) {
                let response = await api.put("/notifications/read", { ids: ids })
                let clone = structuredClone(context.notifications)
                clone.metadata.unread = response.unread
                context.setNotifications(clone)
            }
        }
        catch (e) {
            console.error(e)
        }
    }

    return (
        <>
            <div id="notifications-button" className={styles.container} onClick={handleOpen}>

                {(context.notifications?.metadata.unread === 0 || !context.notifications) &&
                    <NotificationIcon className={styles.notificationIcon} />
                }
                {context.notifications?.metadata.unread > 0 &&
                    <>
                        <NotificationFillIcon className={styles.notificationIcon} />
                        <div className={styles.badge}>
                            {context.notifications.metadata.unread < 10 ? context.notifications.metadata.unread : '9+'}
                        </div>
                    </>
                }
            </div>
            <AnimatePresence>
                <motion.div
                    ref={dropdownRef}
                    style={{ bottom: `-${position.bottom}px` }}
                    className={styles.dropdown}
                    initial={{ opacity: 0, visibility: 'hidden' }}
                    animate={{
                        opacity: context.dropdown === 'notifications' ? 1 : 0,
                        visibility: context.dropdown === 'notifications' ? 'visible' : 'hidden'
                    }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.2 }}
                >
                    {(!context.notifications || context.notifications?.page.length === 0) &&
                        <div className={typo.headline} style={{ padding: '1rem' }}>Non ci sono notifiche.</div>
                    }
                    {context.notifications?.page.length > 0 &&
                        <>
                            <Each of={context.notifications.page} render={(notification) => {
                                return (
                                    <div className={styles.notification} style={{ cursor: notification.url ? 'pointer' : 'default' }} onClick={() => { handleNotificationClick(notification) }}>
                                        <div className={styles.inner}>
                                            {notification.thumbnail &&
                                                <img src={notification.thumbnail} className={styles.thumbnail} alt="" />
                                            }
                                            <div className={styles.info}>
                                                <div className={`${styles.title} ${notification.read_at ? styles.read : ''}`}>
                                                    {notification.title}
                                                </div>
                                                <div className={`${styles.body} ${notification.read_at ? styles.read : ''}`}>
                                                    {notification.message}
                                                </div>
                                            </div>
                                            <div className={styles.time}>
                                                {calcLastUpdate(notification.created_at, true)}
                                                <ClockIcon className={styles.clockIcon} />
                                            </div>
                                        </div>
                                    </div>
                                )
                            }} />
                            <Button appearance="text" style={{ fontSize: '.875rem', width: '100%', padding: '.5rem' }}
                                onClick={() => {
                                    navigate("/notifications")
                                    context.setDropdown(null)
                                }}>vai alle notifiche</Button>
                        </>
                    }

                </motion.div>
            </AnimatePresence >
        </>
    )
}

export default NotificationsDropdown