import styles from "./MacroEditor.module.css"
import typo from "../../typography.module.css"
import HeaderFooterLayout from "../layouts/HeaderFooterLayout"
import TextareaAutosize from 'react-textarea-autosize'
import { Helmet, HelmetProvider } from "react-helmet-async"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { getDefaultSection, hasProperty } from "../../utils"
import { Each } from "../../common/Each"
import Section from "./Section"
import Dropzone from "react-dropzone"
import ReactPlayer from "react-player"
import { ReactComponent as InsertDocumentIllustration } from "../../assets/images/illustrations/il-insert-document.svg"
import { ReactComponent as TextAlignCenterIcon } from "../../assets/images/icons/ic-text-align-center.svg"
import { ReactComponent as TextAlignLeftIcon } from "../../assets/images/icons/ic-text-align-left.svg"
import { ReactComponent as TextAlignRightIcon } from "../../assets/images/icons/ic-text-align-right.svg"
import { ReactComponent as TextAlignJustifyIcon } from "../../assets/images/icons/ic-text-align-justify.svg"
import { ReactComponent as EyeIcon } from "../../assets/images/icons/ic-eye.svg"
import { ReactComponent as ClosedEyeIcon } from "../../assets/images/icons/ic-eye-closed.svg"
import { ReactComponent as SendIcon } from "../../assets/images/icons/ic-send.svg"
import Button from "../Button"
import { ReactComponent as CloseIcon } from "../../assets/images/icons/ic-close.svg"
import AlertDialog from "../dialogs/AlertDialog"
import Card from "../cards/Card"
import TextInput from "../TextInput"
import api from "../../api"
import MacroCardPreview from "./MacroCardPreview"
import DropdownMultiSelection from "../DropdownMultiSelection"
import { ButtonStatus } from "../../common/constants"
import { DialogStatus } from "../../enums"
import { useNavigate, useParams } from "react-router-dom"
import SimpleCollapsable from "../SimpleCollapsable"
import { v4 } from "uuid"
import Select from "../../components/Select";
import Back from "../Back"


const MacroEditor = ({ }) => {

  const { macroId } = useParams()
  const navigate = useNavigate()
  const [previewId, setPreviewId] = useState(null)
  const [sections, setSections] = useState([])
  const [careers, setCareers] = useState([])
  const [selectedCareers, setSelectedCareers] = useState([])
  const [name, setName] = useState("")
  const [subtitle, setSubtitle] = useState("")
  const [background, setBackground] = useState(`radial-gradient(at 100% 100%, hsla(240,100%,70%,0.5) 0px, transparent 50%),
radial-gradient(at 0% 0%, hsla(343,100%,76%,0.5) 0px, transparent 50%)`)
  const [description, setDescription] = useState("")
  const [url, setUrl] = useState("")
  const [file, setFile] = useState(null)
  const [videoUrl, setVideoUrl] = useState("")
  const [previewWindow, setPreviewWindow] = useState(null)
  const [selection, setSelection] = useState(null)
  const [showCardPreview, setShowCardPreview] = useState(false)
  const [canBePublished, setCanBePublished] = useState(false)
  const [publishingStatus, setPublishingStatus] = useState(null)
  const [openAlert, setOpenAlert] = useState(false)
  const [alertStatus, setAlertStatus] = useState(DialogStatus.Default)
  const [closeTimeout, setCloseTimeout] = useState(null)
  const [padding, setPadding] = useState({
    total: "",
    top: "",
    bottom: "",
    left: "",
    right: ""
  })
  const [size, setSize] = useState({
    width: "",
    height: ""
  })
  const statusOptions = useMemo(() => {
    return [
      { id: 0, name: 'Bozza', value: 'draft' },
      { id: 1, name: 'Pubblico', value: 'public' },
      { id: 2, name: 'Nascosto', value: 'hidden' }
    ]
  }, [])
  const [selectedStatus, setSelectedStatus] = useState(statusOptions[0])

  const dropzoneRef = useRef(null)

  useEffect(() => {
    const getMacro = async () => {
      try {
        const macro = await api.get(`/admin/macros/${macroId}`)
        const {
          name,
          subtitle,
          description,
          video_url,
          content,
          background,
          status,
          careers
        } = macro
        setName(name)
        setSubtitle(subtitle)
        setDescription(description)
        setVideoUrl(video_url)
        setUrl(video_url)
        setSections(content)
        setBackground(background)
        setSelectedCareers(careers ?? [])
        setSelectedStatus(statusOptions.find(s => s.value === status))
      } catch (e) {
        console.error(e)
      }
    }

    const getCareers = async () => {
      try {
        const res = await api.get("/admin/careers")
        setCareers(res)
      } catch (e) {
        console.error(e)
      }
    }

    if (macroId !== "create") {
      getMacro()
    } else {
      setSections([getDefaultSection()])
    }
    getCareers()

    return () => {
      if (closeTimeout) {
        clearTimeout(closeTimeout)
      }
      if (previewWindow) {
        previewWindow.close()
      }
    }
  }, [])

  useEffect(() => {
    setCanBePublished(!!name && !!description && !!videoUrl && !!subtitle && !!background && selectedCareers.length > 0)
  }, [name, description, videoUrl, subtitle, background, selectedCareers])

  useEffect(() => {
    const uploadMedia = async () => {
      try {
        const formData = new FormData()
        formData.append("file", file)
        const res = await api.post("/admin/upload", formData, { headers: { "Content-Type": "multipart/form-data" } })
        setVideoUrl(res.url)
      } catch (e) {
        console.error(e)
      }
    }

    const removeMedia = async () => {
      try {
        const res = await api.delete(`/admin/upload?url=${videoUrl}`)
        setVideoUrl(null)
      } catch (e) {
        console.error(e)
      }
    }

    if (file) {
      uploadMedia()
    } else if (videoUrl) {
      removeMedia()
    }
  }, [file])

  useEffect(() => {
    onStyleChange({ width: size.width })
    onStyleChange({ height: size.height })
  }, [size])

  useEffect(() => {
    onStyleChange({ "padding-top": padding.top })
    onStyleChange({ "padding-bottom": padding.bottom })
    onStyleChange({ "padding-right": padding.right })
    onStyleChange({ "padding-left": padding.left })
  }, [padding])

  useEffect(() => {
    if (previewWindow) {
      updatePreview()
    }
  }, [sections, videoUrl, description, name, subtitle, background, selectedCareers])

  useEffect(() => {
    if (!previewId) {
      return
    }

    const previewWindow = window.open(`/macroPreview/${previewId}`, "_blank", "width=1024,height=768")
    setPreviewWindow(previewWindow)
    setTimeout(() => {
      updatePreview(previewWindow)
    }, 4000)
  }, [previewId])

  const addSection = useCallback(() => {
    setSections(s => {
      s.push(getDefaultSection())
      return [...s]
    })
  }, [])

  const deleteSection = useCallback((index) => {
    setSections(s => {
      s.splice(index, 1)
      return [...s]
    })
  }, [])

  const updatePreview = useCallback((pw) => {
    const w = previewWindow ?? pw
    const data = {
      name,
      subtitle,
      description,
      video_url: videoUrl,
      content: sections,
      careers: selectedCareers
    }
    w.postMessage({
      type: `macroPreviewUpdateEvent-${previewId}`,
      data: JSON.stringify(data)
    }, `${window.location.protocol}//${window.location.host}`)
  }, [sections, videoUrl, description, previewWindow, name, subtitle, selectedCareers, previewId])


  const onChange = useCallback((i, name, rows) => {
    setSections(s => {
      s[i] = {
        position: i + 1,
        name,
        rows
      }
      return [...s]
    })
  }, [])

  const onStyleChange = useCallback((change) => {
    if (!selection)
      return

    setSelection(s => {
      s.style = { ...s.style, ...change }
      return { ...s }
    })

    setSections(s => {
      const { sectionIndex, rowIndex, columnIndex } = selection

      if (columnIndex !== null) {
        // row inside column
        if (s[sectionIndex].rows[rowIndex].content[columnIndex].style) {
          s[sectionIndex].rows[rowIndex].content[columnIndex].style = {
            ...s[sectionIndex].rows[rowIndex].content[columnIndex].style,
            ...change
          }
        } else {
          s[selection.sectionIndex].rows[rowIndex].content[columnIndex].style = change
        }
      } else {
        // pure row
        if (s[sectionIndex].rows[rowIndex].style) {
          s[sectionIndex].rows[rowIndex].style = {
            ...s[sectionIndex].rows[rowIndex].style,
            ...change
          }
        } else {
          s[selection.sectionIndex].rows[rowIndex].style = change
        }
      }
      return [...s]
    })
  }, [selection, sections])

  const onSelection = useCallback(({ sectionIndex, rowIndex, columnIndex }) => {
    if (rowIndex >= 0) {
      setSelection({ sectionIndex, rowIndex, columnIndex })
      let style = null

      if (columnIndex !== null) {
        style = sections[sectionIndex].rows[rowIndex].content[columnIndex].style
      } else {
        style = sections[sectionIndex].rows[rowIndex].style
      }

      setSelection({ sectionIndex, rowIndex, columnIndex, style })

      setPadding({
        total: "",
        top: style ? style["padding-top"] : "",
        bottom: style ? style["padding-bottom"] : "",
        left: style ? style["padding-left"] : "",
        right: style ? style["padding-right"] : "",
      })

      setSize({
        width: style ? style["width"] : "",
        height: style ? style["height"] : ""
      })
    } else {
      setSelection(null)
    }
  }, [sections])

  const publish = useCallback(async () => {
    setPublishingStatus(ButtonStatus.Loading)
    setAlertStatus(DialogStatus.Loading)

    try {

      const data = {
        name,
        subtitle,
        description,
        status: selectedStatus.value,
        video_url: videoUrl,
        content: JSON.stringify(sections),
        slug: name.replace(/[^a-zA-Z0-9\s-]/g, '') // Rimuovi tutti i caratteri tranne lettere e numeri
          .replace(/\s+/g, '-') // Sostituisci spazi con trattini
          .toLowerCase()
          .trim(),
        background,
        careers_ids: selectedCareers.map(c => c.id)
      }

      const res = macroId !== "create" ?
        await api.put(`/admin/macros/${macroId}`, data) :
        await api.post("/admin/macros", data)

      setPublishingStatus(ButtonStatus.Success)
      setAlertStatus(DialogStatus.Success)
      setCloseTimeout(setTimeout(() => {
        setOpenAlert(false)
        setAlertStatus(DialogStatus.Default)
      }, 3000))
    } catch (e) {
      setPublishingStatus(ButtonStatus.Error)
      setAlertStatus(DialogStatus.Error)

      setCloseTimeout(setTimeout(() => {
        setOpenAlert(false)
        setAlertStatus(DialogStatus.Default)
      }, 3000))
      console.error(e)
    }
  }, [name, subtitle, sections, description, videoUrl, background, selectedCareers])

  return (
    <HeaderFooterLayout hideFooter>
      <HelmetProvider>
        <Helmet>
          <title>Area di interesse</title>
        </Helmet>
      </HelmetProvider>
      <div className={styles.container}>
        <div className={styles.section}>
          <div className={styles.sectionInner}>
            <Back onClick={() => navigate("/macros")} />
            <div className={styles.title}>
              <div className={typo.title}>Area di interesse</div>
              <Button
                inverse
                accentColor={"var(--tertiary)"}
                onClick={() => setShowCardPreview(s => !s)}
                style={{ padding: ".7rem .7rem" }}
              >
                {showCardPreview && <> <ClosedEyeIcon /> NASCONDI CARD</>}
                {!showCardPreview && <> <EyeIcon /> MOSTRA CARD</>}

              </Button>
            </div>
            <div className={styles.header}>
              <SimpleCollapsable
                expanded={showCardPreview}
                expadendHeight={"340px"}
              >
                <div className={styles.cardPreview}>
                  <MacroCardPreview
                    macro={{
                      name, subtitle, background, description
                    }}
                  />
                </div>
              </SimpleCollapsable>
              <Select selected={selectedStatus} options={statusOptions} onSelect={(value) => setSelectedStatus(value)} />
              <DropdownMultiSelection
                options={careers}
                selectedOptions={selectedCareers}
                placeholder="Associa le carriere all'area di interesse"
                itemsRender={(career, i) => (
                  <div className={styles.career} onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    const idx = selectedCareers.findIndex(c => c.id === career.id)
                    if (idx >= 0) {
                      setSelectedCareers(c => {
                        c.splice(idx, 1)
                        return [...c]
                      })
                      setCareers(c => [...c, career])
                    } else {
                      setSelectedCareers(c => [...c, career])
                      setCareers(c => {
                        const cIdx = c.findIndex(c => c.id === career.id)
                        if (cIdx >= 0) {
                          c.splice(cIdx, 1)
                        }
                        return [...c]
                      })
                    }
                  }}>
                    {career.name}
                    {
                      selectedCareers.findIndex(c => c.id === career.id) >= 0 &&
                      <CloseIcon className={styles.removeCareer} />
                    }
                  </div>
                )}
              />
              <TextInput
                fullWidth
                value={name}
                type="text"
                placeholder={"Titolo area di interesse"}
                onKeyUp={(s) => setName(s)}
              />
              <TextInput
                fullWidth
                value={subtitle}
                type="text"
                placeholder={"Sottotitolo area di interesse"}
                onKeyUp={(s) => setSubtitle(s)}
              />
              <TextInput
                fullWidth
                value={background}
                type="text"
                placeholder={"Sfondo"}
                onKeyUp={(s) => setBackground(s)}
              />
              <TextareaAutosize
                value={description}
                minRows={3} maxRows={10}
                type="text"
                className={styles.textArea}
                placeholder="Descrizione"
                onChange={(e) => {
                  const { value } = e.target
                  setDescription(value)
                }} />

              <div className={styles.mediaContainer}>
                <div className={styles.label}>Video copertina</div>
                <div className={styles.mediaTop}>
                  {
                    file === null && !url &&
                    <Dropzone
                      maxFiles={1}
                      multiple={false}
                      accept={{
                        'video/mp4': [],
                      }}
                      onDrop={(newFiles) => {
                        if (newFiles && newFiles.length > 0) {
                          setFile(newFiles[0])
                        } else {
                          setFile(null)
                        }
                      }}>
                      {({ getRootProps, getInputProps }) => (
                        <section style={{ display: 'flex', width: '100%', padding: 0, margin: 0 }}>
                          <div {...getRootProps()} style={{ display: 'flex', width: '100%' }} ref={dropzoneRef} >
                            <input {...getInputProps()} />
                            <div className={styles.dropzone}>
                              <InsertDocumentIllustration />
                              <div className={styles.dropzoneLabel}>
                                Trascina o selezione il video
                              </div>
                              <Button
                                style={{ marginTop: '.5rem', padding: '0.6rem 2rem' }}
                                onClick={() => {
                                  if (dropzoneRef && dropzoneRef.current) {
                                    dropzoneRef.current.click();
                                  }
                                }}
                                accentColor={"var(--tertiary)"}
                              >
                                SELEZIONA
                              </Button>
                            </div>
                          </div>
                        </section>
                      )}
                    </Dropzone>
                  }
                  {
                    url &&
                    <div className={styles.media}>
                      <ReactPlayer
                        url={url}
                        controls
                        width="100%"
                        height="100%"
                        className={styles.player}
                      />
                    </div>
                  }
                  {
                    file &&
                    <div className={styles.media}>
                      <div className={styles.relative}>
                        <ReactPlayer
                          url={file ? URL.createObjectURL(file) : ""}
                          controls
                          width="100%"
                          height="100%"
                          className={styles.player}
                        />
                        <button className={styles.removeMedia} onClick={() => setFile(null)}>
                          <CloseIcon />
                        </button>
                      </div>
                    </div>
                  }
                  {
                    !url &&
                    <div className={styles.mediaHelperText}>Oppure inserisci l'URL del video</div>
                  }
                </div>

                <Card hover style={{ padding: ".5rem 1rem", marginTop: ".5rem", width: "100%" }} title={`URL video`}>
                  <TextInput
                    type={"text"}
                    value={url}
                    onKeyUp={(value) => {
                      setUrl(value)
                      setVideoUrl(value)
                      if (value) {
                        setFile(null)
                      }
                    }}
                    style={{
                      padding: 0, border: "none", backgroundColor: "transparent",
                      fontSize: "1rem", fontWeight: 700, width: "100%",
                      borderRadius: 0
                    }} />
                </Card>
              </div>

            </div>
            <Each
              of={sections}
              render={(section, i) => (
                <Section
                  {...section}
                  selection={selection}
                  position={i + 1}
                  onDelete={deleteSection}
                  onChange={(name, rows) => onChange(i, name, rows)}
                  onSelection={(args => onSelection({ sectionIndex: i, ...args }))}
                />
              )}
            />

            <div className={styles.addSection} onClick={addSection}>
              + Aggiungi sezione
            </div>
          </div>
          <div className={styles.styleBar}>
            <div className={styles.actions}>
              <Button
                inverse
                fullWidth
                accentColor={"var(--primary)"}
                onClick={() => setPreviewId(v4())}
                style={{ padding: ".7rem .7rem" }}
              >
                <EyeIcon />
                ANTEPRIMA
              </Button>
              {
                selectedStatus.value === "public" &&
                <Button
                  fullWidth
                  disabled={canBePublished === false}
                  accentColor={"var(--primary)"}
                  onClick={() => setOpenAlert(true)}
                  style={{ padding: ".7rem .7rem" }}
                  status={publishingStatus}
                >
                  {publishingStatus === null && <SendIcon />}
                  PUBBLICA
                </Button>
              }
              {
                selectedStatus.value === "hidden" &&
                <Button
                  fullWidth
                  disabled={canBePublished === false}
                  accentColor={"var(--primary)"}
                  onClick={() => setOpenAlert(true)}
                  style={{ padding: ".7rem .7rem" }}
                  status={publishingStatus}
                >
                  {publishingStatus === null && <SendIcon />}
                  PUBBLICA NASCOSTO
                </Button>
              }
              {
                selectedStatus.value === "draft" &&
                <Button
                  fullWidth
                  disabled={!name}
                  accentColor={"var(--primary)"}
                  onClick={() => setOpenAlert(true)}
                  style={{ padding: ".7rem .7rem" }}
                  status={publishingStatus}
                >
                  {publishingStatus === null && <SendIcon />}
                  SALVA IN BOZZA
                </Button>
              }

            </div>
            <div className={styles.styleBarHeader}>
              <div className={styles.indexes}>
                Sezione
                <div>
                  {selection && selection?.sectionIndex >= 0 ? selection.sectionIndex + 1 : "-"}
                </div>
              </div>
              <div className={styles.indexes}>
                Riga
                <div>
                  {selection && selection?.rowIndex >= 0 ? selection.rowIndex + 1 : "-"}
                </div>
              </div>
              <div className={styles.indexes}>
                Colonna
                <div>{(selection && selection?.columnIndex !== null) ? selection.columnIndex + 1 : "-"}</div>
              </div>
            </div>
            <div className={styles.property}>
              <div className={styles.propertyLabel}>Allineamento testo</div>
              <div className={styles.buttons}>
                <button
                  disabled={selection === null}
                  onClick={() => {
                    const change = hasProperty(selection?.style, "textAlign", "left") ? "" : "left"
                    onStyleChange({ textAlign: change })
                  }}
                  className={hasProperty(selection?.style, "textAlign", "left") ? `${styles.styleButton} ${styles.selected}` : styles.styleButton}>
                  <TextAlignLeftIcon />
                </button>
                <button
                  disabled={selection === null}
                  onClick={() => {
                    const change = hasProperty(selection?.style, "textAlign", "right") ? "" : "right"
                    onStyleChange({ textAlign: change })
                  }}
                  className={hasProperty(selection?.style, "textAlign", "right") ? `${styles.styleButton} ${styles.selected}` : styles.styleButton}>
                  <TextAlignRightIcon />
                </button>
                <button
                  disabled={selection === null}
                  onClick={() => {
                    const change = hasProperty(selection?.style, "textAlign", "justify") ? "" : "justify"
                    onStyleChange({ textAlign: change })
                  }}
                  className={hasProperty(selection?.style, "textAlign", "justify") ? `${styles.styleButton} ${styles.selected}` : styles.styleButton}>
                  <TextAlignJustifyIcon />
                </button>
                <button
                  disabled={selection === null}
                  onClick={() => {
                    const change = hasProperty(selection?.style, "textAlign", "center") ? "" : "center"
                    onStyleChange({ textAlign: change })
                  }}
                  className={hasProperty(selection?.style, "textAlign", "center") ? `${styles.styleButton} ${styles.selected}` : styles.styleButton}>
                  <TextAlignCenterIcon />
                </button>
              </div>
            </div>
            <div className={styles.property}>
              <Card hover style={{ padding: ".3rem 1rem", height: "100%", textAlign: "start" }} title={"Width"}>
                <TextInput
                  disabled={selection === null}
                  value={size.width}
                  type="text"
                  placeholder={"50%"}
                  onKeyUp={(value) => {
                    setSize(s => {
                      return {
                        ...s,
                        width: value
                      }
                    })
                  }}
                  style={{
                    padding: 0, border: "none", backgroundColor: "transparent",
                    width: "fit-content",
                    borderRadius: 0
                  }}
                />
              </Card>
              <Card hover style={{ padding: ".3rem 1rem", height: "100%", textAlign: "start" }} title={"Height"}>
                <TextInput
                  value={size.height}
                  disabled={selection === null}
                  type="text"
                  placeholder={"320px"}
                  onKeyUp={(value) => {
                    setSize(s => {
                      return {
                        ...s,
                        height: value
                      }
                    })
                  }}
                  style={{
                    padding: 0, border: "none", backgroundColor: "transparent",
                    width: "fit-content",
                    borderRadius: 0
                  }}
                />
              </Card>
            </div>
            <div className={styles.property}>
              <Card hover style={{ padding: ".3rem 1rem", height: "100%", textAlign: "start" }} title={"Padding"}>
                <TextInput
                  value={padding.total}
                  disabled={selection === null}
                  type="text"
                  placeholder={"18px 1rem"}
                  onKeyUp={(value) => {
                    if (value) {
                      const split = value.split(" ")
                      if (split.length > 1 && split[1]) {
                        const vertical = split[0]
                        const horizontal = split[1]
                        setPadding(
                          {
                            total: value,
                            top: vertical,
                            bottom: vertical,
                            left: horizontal,
                            right: horizontal
                          }
                        )

                      } else {
                        setPadding({
                          total: value,
                          top: value,
                          bottom: value,
                          left: value,
                          right: value
                        })
                      }
                    } else {
                      setPadding({
                        total: "",
                        top: "",
                        bottom: "",
                        left: "",
                        right: ""
                      })
                    }
                  }}
                  style={{
                    padding: 0, border: "none", backgroundColor: "transparent",
                    width: "fit-content",
                    borderRadius: 0
                  }}
                />
              </Card>
              <div className={styles.paddings}>
                <div className={styles.padding}>
                  <Card hover style={{ padding: ".3rem 1rem", height: "100%", textAlign: "start" }} title={"Top"}>
                    <TextInput fullWidth
                      disabled={selection === null}
                      value={padding.top}
                      type="text"
                      placeholder={"18px"}
                      onKeyUp={(value) => {
                        setPadding(p => {
                          return {
                            ...p,
                            top: value
                          }
                        })
                      }}
                      style={{
                        padding: 0, border: "none", backgroundColor: "transparent",
                        width: "fit-content",
                        borderRadius: 0
                      }}
                    />
                  </Card>
                </div>
                <div className={styles.padding}>
                  <Card hover style={{ padding: ".3rem 1rem", height: "100%", textAlign: "start" }} title={"Bottom"}>
                    <TextInput fullWidth
                      disabled={selection === null}
                      value={padding.bottom}
                      type="text"
                      placeholder={"18px"}
                      onKeyUp={(value) => {
                        setPadding(p => {
                          return {
                            ...p,
                            bottom: value
                          }
                        })
                      }}
                      style={{
                        padding: 0, border: "none", backgroundColor: "transparent",
                        width: "fit-content",
                        borderRadius: 0
                      }}
                    />
                  </Card>
                </div>
                <div className={styles.padding}>
                  <Card hover style={{ padding: ".3rem 1rem", height: "100%", textAlign: "start" }} title={"Left"}>
                    <TextInput fullWidth
                      disabled={selection === null}
                      value={padding.left}
                      type="text"
                      placeholder={"1rem"}
                      onKeyUp={(value) => {
                        setPadding(p => {
                          return {
                            ...p,
                            left: value
                          }
                        })
                      }}
                      style={{
                        padding: 0, border: "none", backgroundColor: "transparent",
                        width: "fit-content",
                        borderRadius: 0
                      }}
                    />
                  </Card>
                </div>
                <div className={styles.padding}>
                  <Card hover style={{ padding: ".3rem 1rem", height: "100%", textAlign: "start" }} title={"Right"}>
                    <TextInput fullWidth
                      disabled={selection === null}
                      value={padding.right}
                      type="text"
                      placeholder={"1rem"}
                      onKeyUp={(value) => {
                        setPadding(p => {
                          return {
                            ...p,
                            right: value
                          }
                        })
                      }}
                      style={{
                        padding: 0, border: "none", backgroundColor: "transparent",
                        width: "fit-content",
                        borderRadius: 0
                      }}
                    />
                  </Card>
                </div>
              </div>
            </div>

          </div>
        </div>

        <AlertDialog
          open={openAlert}
          title={"Pubblica area di interesse"}
          text={"Prima di pubblicare verifica di aver impostato correttamente contenuto e stile tramite l'anteprima.\n Sei sicuro di voler pubblicare l'area di interesse?"}
          status={alertStatus}
          onClose={() => {
            if (alertStatus === DialogStatus.Success) {
            }

            setOpenAlert(false)
            setAlertStatus(DialogStatus.Default)
            if (closeTimeout) {
              clearTimeout(closeTimeout)
            }
          }}
          actions={[
            {
              label: alertStatus === DialogStatus.Success ? "OK" : "PUBBLICA",
              onClick: alertStatus === DialogStatus.Success ? () => {setOpenAlert(false)} : publish,
            }
          ]}
        />
      </div>

    </HeaderFooterLayout>
  )
}


export default MacroEditor
