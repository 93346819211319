import styles from "./FAQAccordions.module.css"
import { Each } from "../common/Each"
import { useState } from "react"
import useWindowDimensions from "../common/hooks/useWindowDimensions"
import FAQCollapsible from "./FAQCollapsible"

const FAQAccordions = ({ faq }) => {

    const { width } = useWindowDimensions()

    const [expanded, setExpanded] = useState(null)

    const handleExpand = (index) => {
        setExpanded(index)
    }

    return (
        <div className={styles.container}>
            <Each of={faq} render={(module, index) => {
                return (
                    <>
                        <div className={styles.row}>
                            
                            <div className={styles.accordions}>
                                <div className={styles.accordion}>
                                    {/** 
                                     * Remove expanded and onExpand if want to allow to expand all the accordions
                                    */}
                                    <FAQCollapsible title={module.name} description={module.description} items={module.arguments} expanded={expanded === index} onExpand={() => { handleExpand(index) }} />
                                </div>
                            </div>
                        </div>
                    </>
                )
            }} />

        </div>
    )
}

export default FAQAccordions
