import { useEffect, useState } from 'react';
import styles from "./TicTacToe.module.css"
import { Each } from '../common/Each';
import Button from './Button';
import typo from "../typography.module.css"

const TicTacToe = ({ height, width }) => {
    const [isComputerPlaying, setIsComputerPlaying] = useState(randomBoolean());
    const [gameEnded, setGameEnded] = useState(false);
    const [winner, setWinner] = useState(null)
    const [winningCombo, setWinningCombo] = useState(null)

    const [gameBoard, setGameBoard] = useState(Array(9).fill(null));
    const winningCombos = [[0, 1, 2], [3, 4, 5], [6, 7, 8], [0, 3, 6], [1, 4, 7], [2, 5, 8], [0, 4, 8], [2, 4, 6]];

    function randomBoolean() {
        return Math.random() < 0.5;
    }

    const updateBoard = (index, value) => {
        const newGameBoard = [...gameBoard]
        newGameBoard[index] = value
        setGameBoard(newGameBoard)
    };

    const reset = () => {
        setGameBoard(Array(9).fill(null));
        setGameEnded(false)
        setWinner(null)
        setWinningCombo(null)
        setIsComputerPlaying(randomBoolean())
    };

    const checkWinner = () => {
        for (let combo of winningCombos) {
            const [a, b, c] = combo
            if (gameBoard[a] && gameBoard[a] === gameBoard[b] && gameBoard[a] === gameBoard[c]) {
                setWinner(gameBoard[a])
                setWinningCombo([a, b, c])
                setGameEnded(true)
                return gameBoard[a]
            }
        }
        return null;
    }

    const calculateComputerMove = () => {

        var cellRank = [3, 2, 3, 2, 4, 2, 3, 2, 3];

        if (gameBoard.every(value => value === null)) {
            cellRank = [3, 2, 3, 2, 2, 2, 3, 2, 3]
        }

        if (gameBoard[0] === "O") {
            if (gameBoard[8] === null) {
                cellRank[8] += 5
            }
            else if (gameBoard[8] === gameBoard[0]) {
                cellRank[2] += 5
                cellRank[6] += 5
            }
        }
        if (gameBoard[8] === "O") {
            if (gameBoard[0] === null) {
                cellRank[0] += 5
            }
            else if (gameBoard[0] === gameBoard[8]) {
                cellRank[2] += 5
                cellRank[6] += 5
            }
        }
        if (gameBoard[2] === "O") {
            if (gameBoard[6] === null) {
                cellRank[6] += 5
            }
            else if (gameBoard[2] === gameBoard[6]) {
                cellRank[0] += 5
                cellRank[8] += 5
            }
        }
        if (gameBoard[6] === "O") {
            if (gameBoard[2] === null) {
                cellRank[2] += 5
            }
            else if (gameBoard[6] === gameBoard[2]) {
                cellRank[0] += 5
                cellRank[8] += 5
            }
        }

        if (gameBoard[0] === "X") {
            cellRank[1] += 5
            cellRank[3] += 5
        }
        if (gameBoard[2] === "X") {
            cellRank[1] += 5
            cellRank[5] += 5
        }
        if (gameBoard[6] === "X") {
            cellRank[3] += 5
            cellRank[7] += 5
        }
        if (gameBoard[8] === "X") {
            cellRank[7] += 5
            cellRank[5] += 5
        }


        for (var i = 0; i < gameBoard.length; i++) {
            if (gameBoard[i] !== null) {
                cellRank[i] -= 99;
            }
        }

        let possibleWin = []
        let possibleLoss = []

        for (let combo of winningCombos) {
            var [a, b, c] = combo
            if (gameBoard[a] === gameBoard[b]) {
                if (gameBoard[a] !== null) {
                    if (gameBoard[c] === null) {
                        if (gameBoard[a] === 'X') {
                            possibleLoss.push(combo)
                        }
                        else {
                            possibleWin.push(combo)
                        }
                    }
                }
            }

            if (gameBoard[a] === gameBoard[c]) {
                if (gameBoard[a] !== null) {
                    if (gameBoard[b] === null) {
                        if (gameBoard[a] === 'X') {
                            possibleLoss.push(combo)
                        }
                        else {
                            possibleWin.push(combo)
                        }
                    }
                }
            }

            if (gameBoard[b] === gameBoard[c]) {
                if (gameBoard[b] !== null) {
                    if (gameBoard[a] === null) {
                        if (gameBoard[b] === 'X') {
                            possibleLoss.push(combo)
                        }
                        else {
                            possibleWin.push(combo)
                        }
                    }
                }
            }

            if (gameBoard[a] === gameBoard[c] && gameBoard[b] !== null){
                cellRank[4] += 6
            }
        }

        if (possibleWin.length > 0) {
            let [a, b, c] = possibleWin[0]
            if (gameBoard[a] === null) {
                cellRank[a] += 12
            }
            else if (gameBoard[b] === null) {
                cellRank[b] += 12
            }
            else {
                cellRank[c] += 12
            }
        }
        else if (possibleLoss.length > 0) {
            let [a, b, c] = possibleLoss[0]
            if (gameBoard[a] === null) {
                cellRank[a] += 12
            }
            else if (gameBoard[b] === null) {
                cellRank[b] += 12
            }
            else {
                cellRank[c] += 12
            }
        }


        // Find all indices of maximum values within cellRank
        const maxIndices = [];
        const max = Math.max(...cellRank);
        for (let i = 0; i < cellRank.length; i++) {
            if (cellRank[i] === max) {
                maxIndices.push(i);
            }
        }

        // Generate a random number to select one of the max value indices
        const randomIndex = Math.floor(Math.random() * maxIndices.length);
        // Use the random index to get the corresponding value from cellRank
        const bestCell = maxIndices[randomIndex];

        return bestCell;
    }

    const computerMove = () => {
        const squareIndex = calculateComputerMove();
        setTimeout(() => {
            if (gameBoard.includes(null) && !winner && gameBoard[squareIndex] === null) {
                updateBoard(squareIndex, 'O');
                setIsComputerPlaying(false)
            }
        }, 500);
    };

    const userMove = async (index) => {
        const squareIndex = parseInt(index);
        if (gameBoard.includes(null) && !winner && gameBoard[squareIndex] === null) {
            updateBoard(squareIndex, 'X')
            let winner = checkWinner()
            if (!winner) {
                setIsComputerPlaying(true)
            }
            else{
                setGameEnded(true)
            }
        }
    };

    useEffect(() => {
        if (isComputerPlaying && !winner) {
            if (!gameBoard.includes(null)) {
                setWinner('D');
                setGameEnded(true)
            }
            else{
                computerMove();
            }
        } else {
            let w = checkWinner();
            if (!gameBoard.includes(null) && !w) {
                setWinner('D');
                setGameEnded(true)
            }
        }
    }, [isComputerPlaying, gameBoard, winner]);

    return (
        <>
            <div className={styles.result}>
                {!winner && !isComputerPlaying &&
                    <div className={typo.title} style={{ fontSize: '1rem' }}>
                        Tocca a te!
                    </div>
                }
                {!winner && isComputerPlaying &&
                    <>
                        {gameBoard.every(v => v === null) &&
                            <div className={typo.title} style={{ fontSize: '1rem' }}>
                                Inizio io!
                            </div>
                        }
                        {gameBoard.find(v => v !== null) &&
                            <div className={typo.title} style={{ fontSize: '1rem' }}>
                                Tocca a me!
                            </div>
                        }
                    </>

                }
                {winner === "X" &&
                    <div className={typo.title} style={{ fontSize: '1rem' }}>
                        Hai Vinto!
                    </div>
                }
                {winner === "O" &&
                    <div className={typo.title} style={{ fontSize: '1rem' }}>
                        Hai Perso!
                    </div>
                }
                {winner === 'D' &&
                    <div className={typo.title} style={{ fontSize: '1rem' }}>
                        Pareggio!
                    </div>
                }
            </div>
            <div className={styles.container} style={{ width: width, height: height }}>
                <div className={styles.gameboard}>
                    <Each of={gameBoard} render={(value, index) => {
                        let style = ''
                        if (winningCombo && winningCombo.includes(index)) {
                            if (gameBoard[index] === 'X') {
                                style = styles.winning
                            }
                            else {
                                style = styles.losing
                            }
                        }
                        return (
                            <button className={`${styles.square} ${style}`} onClick={() => { userMove(index) }}>
                                <div className={styles.value} style={{ color: value === 'X' ? 'var(--primary)' : 'var(--secondary)' }}>
                                    {value}
                                </div>
                            </button>
                        )
                    }} />
                </div>

            </div>
            <div className={styles.actions}>
                <Button onClick={reset}>RICOMINCIA</Button>
            </div>
        </>
    );
}

export default TicTacToe;
