
export const CourseType = {
    Masterclass: "masterclass",
    Professional: "professional"
}

export const CourseStatus = {
    ToStart: "to_start",
    InProgress: "in_progress",
    Ended: "ended"
}

export const LessonStatus = {
    ToStart: "to_start",
    Live: "live",
    Ended: "ended",
    All: ["live", "to_start", "ended"]
}

export const TagType = {
    General: "general",
    Feature: "feature"
}

export const ArticleCardType = {
    Large: "large",
    Medium: "medium",
    Small: "small"
}

export const FeedType = {
    Lesson: "lesson",
    Exercise: "exercise",
    Material: "material",
    Communication: "communication"
}

export const palette = ['#1F94FF', '#FFD600', '#F14FFF', '#FF9A8C', '#2fc6a0', '#B966FA']

export const Stripe = {
    publicKey: 'pk_test_51OkqgmHGtSMxXXMSjWJSEeMgIsM2adVltIURTRJcNYUvJfGKr262V0qCTx1aMu29EMDQ7qkHdXVkwW1Y2Vv5Jy8b00q4ZoPPYN'
}

export const FirebaseKeys = {
    Vapid: "BG_K8LFgwQ1cBGLeSV53PKdYd9e38L8YZKkKlVkdi5X_KqsuzOQo4ApJ7gAx9QzUVEeSaUkRf6FLNo3QOq3PCkM"
}

export const PaymentStatus = {
    Succeeded: 'succeeded',
    Failed: 'failed',
    Pending: 'pending',
    Refunded: 'refunded',
    Processing: 'processing'
}

export const NotificationType = {
    LessonLive: 'lesson_live',
    LessonEdit: 'lesson_edit',
    ExerciseReminder: 'exercise_reminder',
    ExerciseResult: 'exercise_result',
    ExerciseExpired: 'exercise_expired',
    MaterialUpload: 'material_upload',
    CourseCompleted: 'course_completed',
    Announcement: 'announcement'
}

export const RowType = {
    Text: "text",
    Image: "image",
    Video: "video",
    Columns: "columns"
}

export const ButtonStatus = {
    Loading: "loading",
    Success: "success",
    Error: "error"
}

export const AccountStatus = {
    Active: "active",
    Suspended: "suspended"
}

export const ProfileType = {
    User: "user",
    Teacher: "teacher",
    Admin: "admin"
}

export const Role = {
    Teacher: "teacher",
    Tutor: "tutor"
}

export const UserPages = {
    Students: "students",
    Teachers: "teachers",
    Tutors: "tutors",
    Admins: "admins",
}

export const AdminRole = {
    Main: "main",
    Sales: "sales"
}
