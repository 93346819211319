import { useEffect, useState } from "react";
import styles from "./CircularProgress.module.css"
import { Each } from "../common/Each";

const CircularProgress = ({ data, content = { style: {} } }) => {
    const gap = 6;
    const [displayData, setDisplayData] = useState([]);
    const [percentage, setPercentage] = useState(0)
    const totalLength = 100;

    useEffect(() => {

        const sum = data.map(d => d.value).reduce((accumulator, currentValue) => accumulator + currentValue, 0);
        setPercentage(sum)

        let d = data.map(x => {
            if (x.value > gap && x.value <= 99) {
                return { value: x.value, color: x.color }
            }
            return x
        })
        if (sum < 100) {
            let n = 100 - sum
            d.push({ value: n, color: '#E1E6EE' })
        }


        setDisplayData(d)

    }, [data])

    return (
        <div className={styles.container}>
            <svg
                width="240"
                height="240"
                viewBox="0 0 240 240"
                className={styles.circle}
                style={{ transform: 'rotate(90deg)' }}
            >
                <Each
                    of={displayData}
                    render={(d, index) => {
                        if (d.value === 0) {
                            return <></>
                        }
                        let prevItemsLength = 0
                        for (const p of displayData.slice(0, index)) {
                            if (p.value > 0) {
                                prevItemsLength += p.value
                            }
                        }

                        const offset = prevItemsLength === 0 ? 0 : prevItemsLength
                        const value = d.value - gap
                        return <circle
                            key={index}
                            cx="120"
                            cy="120"
                            r="100"
                            fill="none"
                            strokeLinecap="round"
                            stroke={d.color}
                            strokeWidth="25"
                            pathLength={totalLength}
                            strokeDasharray={`${value} ${totalLength - value} `}
                            strokeDashoffset={-offset}
                        />
                    }}
                />
                <text x="50%" y="50%" textAnchor="middle" dy=".3em"
                    fontSize="40"
                    fontFamily="Manrope"
                    fontWeight="800"
                    fill="#2A2A2A" transform="rotate(-90, 120, 120)"
                    {...content?.style ?? {}}
                >
                    {content?.label}

                    {!content?.label && `${percentage}%`}
                </text>
                {content?.sublabel &&
                    <text x="50%" y="62.5%" textAnchor="middle" dy=".3em"
                        fontSize="14"
                        fontFamily="Manrope"
                        fontWeight="800"
                        fill="rgba(var(--text-color-rgb), 40%)" transform="rotate(-90, 120, 120)"
                    >
                        {content.sublabel}
                    </text>
                }
            </svg>
        </div>
    );
};

export default CircularProgress;
