import { useEffect, useRef, useState } from "react"
import styles from "./TagSelector.module.css"
import { Each } from "../common/Each"
import Tag from "../components/Tag"
import { ReactComponent as CloseIcon } from "../assets/images/icons/ic-close.svg"
import useClickOutside from "../common/hooks/useClickOutside"
import { ReactComponent as ChevronIcon } from "../assets/images/icons/ic-chevron.svg"

const TagSelector = ({ selected, options, placeholder, style, onRemove = () => { }, onAdd = () => { } }) => {

    const [value, setValue] = useState('')
    const [isFocused, setIsFocused] = useState(false)
    const [optionsOpen, setOptionsOpen] = useState(false)
    const [filterOptions, setFilterOptions] = useState(null)
    const [top, setTop] = useState(null)
    const containerRef = useRef(null)
    const ref = useRef(null)

    const handleClickOutside = () => {
        setOptionsOpen(false)
    }

    useClickOutside(containerRef, handleClickOutside)


    useEffect(() => {
        if (options) {
            setFilterOptions(options)
            if(options.length === 0){
                setOptionsOpen(false)
            }
        }
    }, [options])

    useEffect(() => {
        if (!containerRef.current) return;
        const resizeObserver = new ResizeObserver(() => {
            setTop(containerRef.current.clientHeight)
        });
        resizeObserver.observe(containerRef.current);
        return () => resizeObserver.disconnect(); // clean up 
    }, []);

    const handleOnChange = async (e) => {
        const value = e.target.value
        setValue(value)
        if (value.length > 0) {
            const filtered = options.filter(o => {
                return o.name.replaceAll(' ', '').trim().toLowerCase().includes(value.replaceAll(' ', '').toLowerCase().trim())
            })
            setFilterOptions([...filtered])
            setOptionsOpen(filtered.length > 0)
            if (e.key === 'Enter' && filtered.length === 1) {
                e.preventDefault()
                onAdd(filtered[0])
                setValue('')
                setOptionsOpen(false)
            }
        }
        else {
            setOptionsOpen(false)
        }
    }


    return (
        <div className={`${styles.container} ${optionsOpen ? styles.open : ''}`} ref={containerRef}>
            <Each of={selected} render={(item) => {
                return (
                    <Tag tag={item} Icon={CloseIcon} onClickIcon={() => {
                        onRemove(item)
                    }} />
                )
            }} />
            <input
                type="text"
                value={value}
                className={styles.roundedInput}
                placeholder={placeholder}
                onChange={handleOnChange}
                onKeyDown={handleOnChange}
                onFocus={() => { setIsFocused(true) }}
                onBlur={() => { setIsFocused(false) }}
                style={{ ...style }} />
            {filterOptions && filterOptions.length > 0 && optionsOpen &&
                <div className={styles.options} ref={ref} style={{ top: `${top}px` }}>
                    <Each of={filterOptions} render={(item) => {
                        return (
                            <Tag tag={item} 
                            onClick={() => {
                                onAdd(item)
                                setValue('')
                            }} />
                        )
                    }} />
                </div>
            }

            <ChevronIcon className={`${styles.chevron} ${optionsOpen ? styles.open : ''}`} onClick={() => {
                if (options.length > 0) {
                    setOptionsOpen(!optionsOpen)
                }
            }} />

        </div>
    )

}

export default TagSelector