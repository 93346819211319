import styles from "./StatisticCard.module.css"
import { useTranslation } from "react-i18next";
import { ReactComponent as ChevronFilledIcon } from "../../assets/images/icons/ic-chevron-filled.svg"
import { ReactComponent as EqualIcon } from "../../assets/images/icons/ic-equal.svg"

const StatisticsCard = ({
  Icon = <></>,
  color = "",
  backgroundColor = "",
  value = "",
  label = "",
  increment = null,
  style = {}
}) => {

  const { t } = useTranslation();

  return (
    <div className={styles.container} style={style}>
      <div className={styles.header}>
        <div className={styles.iconContainer} style={{ backgroundColor: `rgba(var(${color.split("(")[1].split(")")[0]}-rgb), 20%)` }}>
          <Icon className={styles.icon} style={{ color }} />
        </div>
        {
          increment !== null &&
          <div className={styles.increment}>
            <div className={styles.incrementLeft}>
              <div className={styles.incrementValue}>{increment >= 0 ? increment > 0 ? `+${increment}` : `${increment}` : `-${increment}`}</div>
              <div className={styles.incrementLabel}>{t("profile.statistics.thisMonth")}</div>
            </div>
            {increment > 0 && <ChevronFilledIcon className={styles.incrementIcon} />}
            {increment === 0 && <EqualIcon className={styles.equalIcon} />}
            {increment < 0 && <ChevronFilledIcon className={`${styles.incrementIcon} ${styles.reverse}`} />}
          </div>
        }
      </div>
      <div className={styles.content}>
        <div className={styles.value}>{value}</div>
        <div className={styles.label}>{label}</div>
      </div>
    </div>
  )
}

export default StatisticsCard
