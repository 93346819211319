import { useEffect } from "react"
import styles from "./Skeleton.module.css"

const Skeleton = ({ type, width, height, borderRadius=0 }) => {

    return (
        <>
            {type === "circle" &&
                <div className={styles.circle} style={{ height: height, width: width, minWidth: width, minHeight: height, borderRadius: width }}>
                </div>
            }
            {type === "rect" &&
                <div className={styles.rect} style={{ height: height, width: width, minWidth: width, minHeight: height, borderRadius: borderRadius }}>
                </div>
            }
        </>

    )

}

export default Skeleton