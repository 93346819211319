export const ModulePages = {
  Students: "students",
  Lessons: "lessons",
  Materials: "materials",
  Exercises: "exercises",
  LessonEdit: "lessonEdit"
}

export const FeedContentType = {
  Communication: "communication",
  Material: "material",
  Exercise: "exercise",
  Test: "test",
}

export const DialogStatus = {
  Default: "default",
  Loading: "loading",
  Success: "Success",
  Error: "Error"
}
