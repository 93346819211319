import { useEffect, useState } from "react"
import styles from "./ThemeProvider.module.css"

const ThemeProvider = ({ theme, children }) => {
    const [classes, setClasses] = useState([styles.container, theme])

    useEffect(() => {
        setClasses([styles.container, theme]);
    }, [theme])

    return (
        <div className={classes.join(" ")}>
            {children}
        </div>
    );
}

export default ThemeProvider