import { useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import { useNavigate, useParams } from "react-router-dom";
import TextareaAutosize from 'react-textarea-autosize';
import api from "../api";
import { ReactComponent as CloseIcon } from "../assets/images/icons/ic-close.svg";
import { TagType } from "../common/constants";
import { Each } from "../common/Each";
import Back from "../components/Back";
import Button from "../components/Button";
import Card from "../components/cards/Card";
import TeacherCard from "../components/cards/TeacherCard";
import AlertDialog from "../components/dialogs/AlertDialog";
import HeaderFooterLayout from "../components/layouts/HeaderFooterLayout";
import TagSelector from "../components/TagSelector";
import TextInput from "../components/TextInput";
import { DialogStatus } from "../enums";
import typo from "../typography.module.css";
import styles from "./ModuleEditor.module.css";
import ModuleLessonCard from "../components/cards/ModuleLessonCard"
import Dialog from "../components/dialogs/Dialog";
import LessonEditor from "../components/LessonEditor";
import {
    BlockTypeSelect,
    BoldItalicUnderlineToggles,
    CreateLink,
    diffSourcePlugin,
    DiffSourceToggleWrapper,
    headingsPlugin,
    InsertTable,
    linkDialogPlugin,
    listsPlugin,
    ListsToggle,
    MDXEditor,
    quotePlugin,
    Separator,
    tablePlugin,
    thematicBreakPlugin,
    toolbarPlugin,
    UndoRedo
} from '@mdxeditor/editor'
import '@mdxeditor/editor/style.css'

const ModuleEditor = () => {

    const navigate = useNavigate()
    const [course, setCourse] = useState(null)
    const [edition, setEdition] = useState(null)
    const [module, setModule] = useState(null)
    const { courseId, editionId, moduleId } = useParams()
    const [tags, setTags] = useState(null)
    const [thumbnail, setThumbnail] = useState(null)
    const [alert, setAlert] = useState({ open: false, title: '', text: '', actions: [], status: DialogStatus.Default })
    const [loading, setLoading] = useState(false)
    const [saveDisabled, setSaveDisabled] = useState(true)
    const [teachers, setTeachers] = useState(null)
    const [teacherDialog, setTeacherDialog] = useState(false)
    const [lesson, setLesson] = useState(null)
    const [text, setText] = useState('')

    useEffect(() => {
        const getModule = async (module_id) => {
            try {
                let module = await api.get(`/admin/modules/${module_id}`)
                setModule(module)
            }
            catch (e) {
                console.error(e)
            }
        }

        if (moduleId && moduleId !== 'create') {
            getModule(moduleId)
        }
        if (moduleId && moduleId === 'create') {
            setModule(
                {
                    name: '',
                    description: '',
                    thumbnail: '',
                    duration: 0,
                    tags: [],
                    teachers: [],
                    lessons: []
                }
            )
        }
    }, [moduleId])

    useEffect(() => {
        const getCourse = async () => {
            try {
                const course = await api.get(`/admin/courses/${courseId}`)
                setCourse(course)
            }
            catch (e) {
                console.error(e)
            }
        }

        if (courseId && !course) {
            getCourse()
        }
    }, [courseId])

    useEffect(() => {
        const getEdition = async () => {
            try {
                let edition = await api.get(`/admin/editions/${editionId}`)
                setEdition(edition)
            }
            catch (e) {
                console.error(e)
            }
        }

        if (editionId && !edition) {
            getEdition()
        }
    }, [editionId])

    useEffect(() => {
        const getTags = async () => {
            try {
                let tags = await api.get("/admin/tags?type=all")
                tags = tags.filter(t => {
                    return !module.tags.map(t => t.id).includes(t.id)
                })
                setTags(tags)
            }
            catch (e) {
                console.error(e)
            }
        }

        const getTeachers = async () => {
            try {
                let teachers = await api.get("/admin/teachers")

                teachers = teachers.map(teacher => {
                    teacher.selected = !!module.teachers.find(t => t.id === teacher.id)
                    return teacher
                })
                setTeachers(teachers)
            }
            catch (e) {
                console.error(e)
            }
        }

        if (module && !tags) {
            getTags()
        }

        if (module && !teachers) {
            getTeachers()
        }

        if (module) {
            setSaveDisabled(module.name.trim() === '' || module.description.trim() === '' || (!module.thumbnail && thumbnail === null) || (module.thumbnail.trim() === '' && thumbnail === null))
        }
        if (module && !module.thumbnail && moduleId === 'create') {
            setModule((prev) => {
                prev.thumbnail = "https://starting-finance-dev.fra1.cdn.digitaloceanspaces.com/placeholders/module_placeholder.png"
                return { ...prev }
            })
        }
    }, [module])

    // useEffect(() => {
    //     if (module) {
    //         if (module.teachers && teachers) {
    //             let teachersWithSelected = teachers.map((t) => {
    //                 t.selected = module.teachers.map((t) => t.id).includes(t.id)
    //                 return t
    //             })
    //             setTeachers([...teachersWithSelected])
    //         }
    //     }
    // }, [module, teachers])

    const create = async () => {
        setLoading(true)
        try {
            let form = new FormData()
            form.append('edition_id', editionId)
            form.append('name', module.name)
            form.append('description', module.description)
            form.append('duration', module.duration)
            if (thumbnail) {
                form.append('thumbnailFile', thumbnail)
            }
            form.append('thumbnail', module.thumbnail)
            form.append('tags', JSON.stringify(module.tags.map(t => t.id)))
            form.append('teachers', JSON.stringify(module.teachers.map(t => t.id)))

            const created = await api.post(`/admin/modules`, form, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })

            setModule(created)

            setAlert({
                open: true,
                title: 'Operazione Completata',
                text: 'Il modulo è stato creato con successo.',
                status: DialogStatus.Success,
                actions: [
                    {
                        label: 'CHIUDI',
                        onClick: () => {
                            setAlert((prev) => {
                                prev.open = false
                                return { ...prev }
                            })
                            navigate(`/courses/${courseId}/editions/${editionId}/modules/${created.id}`)
                        }
                    }
                ]
            })
        }
        catch (e) {
            console.error(e)
            setAlert({
                open: true,
                title: 'Operazione Fallita',
                text: e.detail,
                status: DialogStatus.Error,
                actions: [
                    {
                        label: 'OK',
                        onClick: () => {
                            setAlert((prev) => {
                                prev.open = false
                                return { ...prev }
                            })
                        }
                    }
                ]
            })
        }
        setLoading(false)

    }

    const update = async () => {
        setLoading(true)
        try {
            let form = new FormData()
            form.append('name', module.name)
            form.append('description', module.description)
            form.append('duration', module.duration)
            if (thumbnail) {
                form.append('thumbnailFile', thumbnail)
            }
            form.append('thumbnail', module.thumbnail)
            form.append('tags', JSON.stringify(module.tags.map(t => t.id)))
            form.append('teachers', JSON.stringify(module.teachers.map(t => t.id)))

            const updated = await api.put(`/admin/modules/${module.id}`, form, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })

            setModule(updated)

            setAlert({
                open: true,
                title: 'Operazione Completata',
                text: 'I dati sono stati aggiornati con successo',
                status: DialogStatus.Success,
                actions: [
                    {
                        label: 'CHIUDI',
                        onClick: () => {
                            setAlert((prev) => {
                                prev.open = false
                                return { ...prev }
                            })
                        }
                    }
                ]
            })
        }
        catch (e) {
            console.error(e)
            setAlert({
                open: true,
                title: 'Operazione Fallita',
                text: e.detail,
                status: DialogStatus.Error,
                actions: [
                    {
                        label: 'OK',
                        onClick: () => {
                            setAlert((prev) => {
                                prev.open = false
                                return { ...prev }
                            })
                        }
                    }
                ]
            })
        }
        setLoading(false)

    }

    const save = async () => {
        if (moduleId === 'create') {
            create()
        }
        else {
            update()
        }
    }

    return (
        <HeaderFooterLayout hideFooter>
            <div className={styles.container}>
                {module &&

                    <div className={styles.section}>
                        <div className={styles.sectionInner}>
                            <Back onClick={() => { navigate(`/courses/${courseId}/editions/${editionId}`) }} />
                            {course && edition &&
                                <div className={`${typo.title} ${styles.navigation}`}>
                                    <div className={styles.clickable} onClick={() => {
                                        navigate(`/courses/${courseId}`)
                                    }}>{course.name}</div>
                                    <div>{">"}</div>
                                    <div className={styles.clickable} onClick={() => {
                                        navigate(`/courses/${courseId}/editions/${editionId}`)
                                    }}>{edition.name}</div>
                                    <div>{">"}</div>
                                    <div>{module.name}</div>
                                </div>
                            }
                            <Card>
                                <div className={styles.cardInner}>
                                    <div className={styles.row}>
                                        <div className={styles.column}>
                                            <div className={styles.columnInner}>
                                                <div className={typo.caption}>NOME</div>
                                                <TextInput type="text" value={module.name} placeholder="Nome" onKeyUp={(value) => {
                                                    setModule((prev) => {
                                                        prev.name = value
                                                        return { ...prev }
                                                    })
                                                }} />
                                            </div>

                                            <div className={styles.columnInner}>
                                                <div className={typo.caption}>DESCRIZIONE</div>
                                                <TextareaAutosize
                                                    value={module.description}
                                                    minRows={5}
                                                    maxRows={10}
                                                    type="text"
                                                    className={styles.textArea}
                                                    placeholder="Descrizione"
                                                    onChange={(e) => {
                                                        const { value } = e.target
                                                        setModule((prev) => {
                                                            prev.description = value
                                                            return { ...prev }
                                                        })
                                                    }} />
                                            </div>

                                            <div className={styles.columnInner}>
                                                <div className={typo.caption}>TAG</div>
                                                {tags &&
                                                    <TagSelector
                                                        selected={module.tags.filter(t => t.type === TagType.General)}
                                                        options={tags.filter(t => t.type === TagType.General)}
                                                        placeholder={"Cerca tag"}
                                                        onAdd={(item) => {
                                                            const newOptions = tags.filter(t => t.id !== item.id)
                                                            setTags([...newOptions])

                                                            setModule((prev) => {
                                                                if (!prev.tags.map(t => t.id).includes(item.id)) {
                                                                    prev.tags.push(item)
                                                                }
                                                                return { ...prev }
                                                            })
                                                        }}
                                                        onRemove={(item) => {
                                                            const newOptions = [...tags]
                                                            if (!newOptions.map(t => t.id).includes(item.id)) {
                                                                newOptions.push(item)
                                                            }
                                                            setTags([...newOptions])

                                                            setModule((prev) => {
                                                                prev.tags = prev.tags.filter(t => t.id !== item.id)
                                                                return { ...prev }
                                                            })
                                                        }}
                                                    />
                                                }
                                            </div>
                                            <div className={styles.columnInner}>
                                                <div className={typo.caption}>DURATA</div>
                                                <div className={typo.body}><strong>{module.duration}h</strong></div>
                                                <div className={typo.caption}>La durata di un modulo è calcolata automaticamente sulla base delle lezioni contenute nel modulo.</div>
                                            </div>
                                        </div>
                                        <div className={styles.column}>
                                            <div className={styles.columnInner}>
                                                <div className={typo.caption}>THUMBNAIL</div>
                                                <TextInput disabled={module?.thumbnail?.startsWith('blob:')} type="text" value={module?.thumbnail} placeholder="Thumbnail URL" onKeyUp={(value) => {
                                                    setModule((prev) => {
                                                        prev.thumbnail = value ? value : ''
                                                        return { ...prev }
                                                    })
                                                }} />

                                                <Dropzone
                                                    onDrop={(files) => {
                                                        const thumbnailUrl = URL.createObjectURL(files[0])
                                                        setThumbnail(files[0])
                                                        setModule((prev) => {
                                                            prev.thumbnail = thumbnailUrl
                                                            return { ...prev }
                                                        })
                                                    }}
                                                    accept={
                                                        {
                                                            'image/jpeg': ['.jpg', '.jpeg'],
                                                            "image/png": ['.png'],
                                                            "image/svg+xml": ['.svg'],
                                                            "image/webp": ['.webp']
                                                        }
                                                    }
                                                >
                                                    {({ getRootProps, getInputProps }) => (
                                                        <section style={{ display: 'flex', width: '100%', padding: 0, margin: 0 }}>
                                                            <div {...getRootProps()} style={{ display: 'flex', width: '100%' }}>
                                                                <input {...getInputProps()} />
                                                                <div className={styles.dropzone}>
                                                                    {module?.thumbnail &&
                                                                        <div className={styles.thumbnailWrapper}>
                                                                            <img src={module?.thumbnail} alt="" className={styles.thumbnail} />
                                                                            <div className={styles.removeThumbnail} onClick={(e) => {
                                                                                e.stopPropagation()
                                                                                setThumbnail(null)
                                                                                setModule((prev) => {
                                                                                    prev.thumbnail = ''
                                                                                    return { ...prev }
                                                                                })
                                                                            }}>
                                                                                <CloseIcon style={{ height: '16px', width: '16px' }} />
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    <div className={styles.dropzoneLabel}>
                                                                        Trascina o seleziona un'immagine.
                                                                    </div>
                                                                    <Button
                                                                        style={{ marginTop: '.5rem', padding: '0.6rem 2rem' }}
                                                                        accentColor={"var(--tertiary)"}
                                                                    >
                                                                        SCEGLI IMMAGINE
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        </section>
                                                    )}
                                                </Dropzone>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Card>

                            {edition && course &&
                                <>
                                    <div className={[typo.subtitle, styles.header].join(' ')}>
                                        Lezioni • {module.lessons.length}
                                        <Button
                                            onClick={() => {
                                                setLesson({ name: '', description: '', tags: [], module_id: moduleId })
                                            }}>
                                            NUOVA
                                        </Button>
                                    </div>
                                    <div className={styles.lessons}>
                                        <Each of={module.lessons.sort((a, b) => { return new Date(a.starts_at) < new Date(b.starts_at) })} render={(lesson) => {
                                            edition.course = course
                                            lesson.edition = edition
                                            return (
                                                <div className={styles.teacher}>
                                                    <ModuleLessonCard lesson={lesson} onEdit={() => {
                                                        setLesson(lesson)
                                                    }} />
                                                </div>
                                            )
                                        }} />
                                    </div>
                                </>
                            }

                            <div className={[typo.subtitle, styles.header].join(' ')}>
                                Docenti • {module.teachers.length}
                                <Button onClick={() => {
                                    setTeacherDialog(true)
                                }}>
                                    AGGIUNGI
                                </Button>
                            </div>
                            <div className={styles.teachers}>
                                <Each of={module.teachers} render={(teacher) => {
                                    return (
                                        <div className={styles.teacher}>
                                            <TeacherCard style={{ width: '100%' }} teacher={teacher} onClick={() => navigate(`/teachers/${teacher.id}`)} />
                                        </div>
                                    )
                                }} />
                            </div>
                        </div>
                    </div>
                }
            </div >
            <div className={styles.bottomBar}>
                <Button
                    disabled={loading || saveDisabled}
                    onClick={() => {
                        save()
                    }}>
                    {moduleId === 'create' ? "SALVA" : "SALVA MODIFICHE"}
                </Button>
            </div>
            <AlertDialog
                open={alert.open}
                title={alert.title}
                text={alert.text}
                actions={alert.actions}
                status={alert.status}
                onClose={() => {
                    setAlert((prev) => {
                        prev.open = false
                        return { ...prev }
                    })
                }}
            />
            <Dialog
                open={teacherDialog}
                title={"Seleziona Docenti"}
                onClose={() => {
                    setTeacherDialog(false)
                }}
            >
                <div className={styles.teachers}>
                    <Each of={teachers} render={(teacher) => {
                        return (
                            <div className={`${styles.teacherDialogCell} ${teacher.selected ? styles.selected : ''}`}>
                                <TeacherCard
                                    teacher={teacher}
                                    options={{
                                        selected: teacher.selected,
                                        chevron: false,
                                        status: false,
                                        tags: false
                                    }}
                                    onClick={() => {
                                        let newTeachers = teachers.map(t => {
                                            if (t.id === teacher.id) {
                                                t.selected = !t.selected
                                            }
                                            return t
                                        })
                                        setModule((prev) => {
                                            if (prev.teachers.map(t => t.id).includes(teacher.id)) {
                                                prev.teachers = prev.teachers.filter(t => t.id !== teacher.id)
                                            }
                                            else {
                                                prev.teachers = [...prev.teachers, teacher]
                                            }

                                            return { ...prev }
                                        })
                                        setTeachers(newTeachers)
                                    }}
                                />
                            </div>
                        )
                    }} />
                </div>
            </Dialog>

            <Dialog
                open={lesson}
                title={lesson?.id ? lesson.name : 'Nuova Lezione'}
                onClose={() => {
                    setLesson(null)
                }}>
                <LessonEditor lesson={lesson} onSave={(res) => {
                    if (module.lessons.map(l => l.id).includes(lesson.id)) {
                        setModule((prev) => {
                            prev.lessons = prev.lessons.map(l => {
                                if (l.id === res.id) {
                                    l = res
                                }
                                return l
                            })
                            return { ...prev }
                        })
                    }
                    else {
                        setModule((prev) => {
                            prev.lessons = [...prev.lessons, res]
                            return { ...prev }
                        })
                    }
                    setLesson(null)
                }} />
            </Dialog>


        </HeaderFooterLayout >
    )

}

export default ModuleEditor
