import ReactPlayer from "react-player";
import { ReactComponent as ArrowIcon } from "../../assets/images/icons/ic-arrow.svg";
import { Each } from "../../common/Each";
import Button from "../../components/Button";
import typo from "../../typography.module.css";
import styles from "./MacroDetailPreview.module.css";
import MacroSectionPreview from "./MacroSectionPreview";

const MacroDetailPreview = ({ macro, onBack = () => { } }) => {

    const moveToElement = (id) => {
        const elem = document.getElementById(id);
        if (elem) {
            let position = elem.getBoundingClientRect();
            window.scrollTo({ top: position.top - 80, behavior: 'smooth' });

        }
    }

    return (
        <>
            <div className={styles.section} style={{ paddingTop: '1rem', borderBottom: '1px solid rgba(var(--text-color-rgb), 12%)' }}>
                <div className={[styles.sectionInner, styles.back].join(' ')}>
                    <Button appearance="text" style={{ display: 'flex', alignItems: 'center', gap: '0.3rem', paddingBottom: '2rem' }}
                        onClick={() => { onBack() }}>
                        <ArrowIcon style={{ width: '12px', height: '12px', minWidth: '12px', minHeight: '12px' }} />
                        Torna indietro
                    </Button>
                </div>
                <div className={styles.sectionInner}>
                    <div className={styles.main}>
                        <div className={styles.macroLeft}>
                            <div className={typo.subtitle}>{macro.name}</div>
                            <Each of={macro.content} render={(section, index) => {
                                return (
                                    <button
                                        onClick={() => {
                                            moveToElement(section.id)
                                        }}
                                        className={styles.sectionButton}>{index + 1}. {section.name}</button>
                                )
                            }} />
                        </div>
                        <div className={styles.macroRight}>
                            <div className={styles.playerWrapper}>
                                <ReactPlayer
                                    url={macro.video_url}
                                    controls
                                    width="100%"
                                    height="100%"
                                    className={styles.player}
                                />
                            </div>
                        </div>
                    </div>
                </div>


            </div>

            <Each of={macro.content} render={(section, index) => {
                section.position = index + 1
                return (
                    <div className={styles.section} style={{ borderBottom: '1px solid rgba(var(--text-color-rgb), 12%)' }}>
                        <div className={styles.sectionInner}>
                            <MacroSectionPreview section={section} />
                        </div>
                    </div>
                )
            }} />
        </>
    )

}

export default MacroDetailPreview
