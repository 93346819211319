import { useMemo, useState } from "react"
import styles from "./TabItem.module.css"

const TabItem = ({ IconComponent = <></>, text = "", value = "", color, selected = false, onClick = () => { } }) => {

  const [isHover, setIsHover] = useState(false)

  const style = useMemo(() => {
    if (isHover && !selected) {
      return {
        color: `rgba(${color}, 1)`,
        backgroundColor: `rgba(${color}, 0.2)`
      }
    } else if (selected) {
      return {
        color: "var(--background-color)",
        backgroundColor: `rgba(${color}, 0.8)`
      }
    }
    return {
      color: `rgba(${color}, 1)`,
      backgroundColor: "var(--background-color)"
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHover, selected])

  return (
    <div
      onClick={onClick}
      className={styles.container}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      style={{ ...style }}>
      <IconComponent />
      <div>{text}{value ? ` • ${value}` : ""}</div>
    </div>
  )
}

export default TabItem
