import { useEffect, useState } from "react"
import { ReactComponent as CloseIcon } from "../assets/images/icons/ic-union.svg"
import { Each } from "../common/Each"
import styles from "./FAQCollapsible.module.css"

const FAQCollapsible = ({title="", description, items, expanded=false, onExpand}) => {

    const [exp, setExp] = useState(expanded)

    useEffect(() => {
        setExp(expanded ?? false)
    }, [expanded])

    const toggleExpand = () => {
        if(!exp && onExpand && typeof onExpand === 'function'){
            onExpand()
        }
        setExp(!exp)
    }

    return (
        <div className={styles.container}>
            <button className={`${styles.header} ${exp ? styles.expanded : ''}`} onClick={toggleExpand}>
                {title}
                <CloseIcon className={`${styles.closeIcon} ${exp ? styles.expanded : ''}`}></CloseIcon>
            </button>
            {exp &&
                <div className={styles.divider}></div>
            }
            <div className={`${styles.content} ${exp ? styles.expanded : ''}`}>
                {items &&
                <Each of={items} render={(item) => {
                    return(
                        <div className={`${styles.argument} ${exp ? styles.expanded : ''}`}>
                            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>•</div> 
                            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center'}}>
                                {item}
                            </div>
                        </div>
                    )
                }}/>
            }
            {description && description}
            </div>
        </div>
    )
}

export default FAQCollapsible
